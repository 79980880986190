import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { DynamicMenuService } from 'src/app/core/helpers/dynamic-menu.service';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent implements OnInit {
  slug: string;
  page: Page;
  height = 600;
  innerHtml;
  @ViewChild('iframe') iframe: ElementRef;
  constructor(
    private activatedRoute: ActivatedRoute,
    private menuService: DynamicMenuService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((data: ParamMap) => {
      this.slug = data.get('slug');
      this.getPage();
      // this.menuService.refreshActiveMenu(this.slug);
    });
  }
  getPage() {
    this.menuService.getPage(this.slug).subscribe((data) => {
      this.page = data;
      let finalData = JSON.parse(data.body);
      console.log(finalData);
      // this.innerHtml = finalData.chunks.body;
      this.innerHtml = finalData.html;
    });
  }
}
export interface Page {
  title: string;
  body: string;
}
