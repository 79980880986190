<div class="main-sub-menu">
  <ul>
    <li *ngFor="let link of links">
      <a
        class="clean-a"
        [routerLink]="constructRouterLink(link)"
        routerLinkActive="sub-menu-item-active"
        >{{ link.label | translate }}</a
      >
    </li>
  </ul>
</div>
