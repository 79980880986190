import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumToArray',
})
export class EnumToArrayPipe implements PipeTransform {
  transform(data: Object, hasKeys) {
    if (hasKeys) {
      let array = [];
      const keys = Object.keys(data);
      keys.forEach((key) => {
        array.push({ key: data[key] });
      });
      return array;
    } else {
      let array = [];
      const keys = Object.keys(data);
      keys.forEach((key) => {
        array.push({ key: data[key] });
      });
      array.reverse();
      return array.slice(array.length / 2);
    }
  }
}
