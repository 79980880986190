import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HotTableService {
  constructor() {}
  columns: any[] = [];
  columsData: any[] = [];
  dataset: any[] = [];
  initExcelTable: boolean = false;

  tableSettings: any = {
    licenseKey: 'non-commercial-and-evaluation',
    viewportColumnRenderingOffset: 27,
    viewportRowRenderingOffset: 'auto',
    allowInsertColumn: true,
    allowInsertRow: true,
    manualRowResize: true,
    manualColumnResize: true,
    columns: this.columns,
    colHeaders: this.columsData,
    manualRowMove: true,
    manualColumnMove: true,
    contextMenu: true,
    filters: true,
    dropdownMenu: true,
    //rowHeaders: true,
    //colHeaders: true,
    //colWidths: 150,
    // height: 450,
    // allowRemoveColumn: false,
    // allowRemoveRow: false,
    // autoWrapRow: false,
    // autoWrapCol: false,
    // stretchH: "all",
    // width: 924,
    // autoWrapRow: true,
    //height: 487,
    // maxRows: 22,
    // rowHeaders: true,
    /* afterValidate: function (isValid, value, row, prop) {
      if (value == false) {
        console.log(value, row, prop);
        alert('Invalid');
        //Value = isValid
        // row = inserted invalid value
        //prop = row index changed
      }
    }, */
  };
  setExcelData(data) {
    this.dataset = data;
    this.columsData = [];
    this.columns = [];
    this.columsData = Object.getOwnPropertyNames(data[0]);
    this.columsData.shift();
    console.log(this.columsData);
    this.columsData.forEach((element) => {
      let dataSource = { data: element };
      this.columns.push(dataSource);
    });
    this.tableSettings.columns = this.columns;
    this.tableSettings.colHeaders = this.columsData;
    console.log(this.tableSettings.columns);
    console.log(this.tableSettings.colHeaders);
  }
}
