<div class="container-fluid bg-light">
  <app-previous-page></app-previous-page>
  <div class="container">
    <h1 class="c-dark small-h1">
      <span class="c-primary mr-1">&#8226;</span
      >{{ "establishment.titles.t1" | translate }}
    </h1>
  </div>

  <!-- <app-establishment-statistics></app-establishment-statistics> -->
  <div class="pt-5 mt-5">
    <app-establishment-overview></app-establishment-overview>
  </div>
</div>
<!-- <button (click)="showSuccess()">show succes</button>
<button (click)="showError()">show error</button>
<button (click)="showInfo()">show info</button>
<div>
  <h3>handsontable</h3>
  <h3>upload a file</h3>
  <div>
    <input type="file" (change)="onFileChange($event)" />
  </div>
  <div>
    <button (click)="showData()">show data</button>
    <div>
      <hot-table
        *ngIf="initExcelTable"
        [afterChange]="detectChanges"
        [settings]="tableSettings"
        [data]="dataset"
      >
      </hot-table>
    </div>
  </div>
</div>
 -->
