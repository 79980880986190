import { Pipe, PipeTransform } from '@angular/core';
import { RoutesEnum } from '../models';

@Pipe({
  name: 'navigate',
})
export class NavigatePipe implements PipeTransform {
  routes: typeof RoutesEnum = RoutesEnum;
  transform(value: string): string {
    if (this.routes[value.toUpperCase()]) {
      return this.routes[value.toUpperCase()];
    } else {
      return '/404';
    }
  }
}
