import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MenuItem, SubMenuItem } from '../models/classes/menuItem';
import { ApiService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class DynamicMenuService {
  path: string = '/menus';
  menu: MenuItem[] = [];
  menu$ = new BehaviorSubject<MenuItem[]>(null);
  activeSubMenu: SubMenuItem[] = [];
  activeSubMenu$ = new BehaviorSubject<SubMenuItem[]>(null);
  constructor(private apiService: ApiService) {}
  getMenu() {
    return this.apiService.get(this.path).pipe(
      tap((data) => {
        this.constractMenu(data.data);
      })
    );
  }
  getPage(slug) {
    return this.apiService.get('/pages/slug/' + slug);
  }
  constractMenu(data) {
    data.forEach((item) => {
      if (!item.parent_id) {
        let children = [];
        if (item.children.length) {
          item.children.forEach((child) => {
            let label = child.page
              ? child.page.label
              : child.external_page.label;
            let slug = child.page ? child.page.slug : child.external_page.link;
            let isSystem = child.page ? child.page.isSystem : null;

            children.push(
              new SubMenuItem({
                id: child.id,
                label: label,
                url: slug,
                isSystem: isSystem,
              })
            );
          });
        }
        let label = item.page ? item.page.label : item.external_page.label;
        let slug = item.page ? item.page.slug : item.external_page.link;
        let isSystem = item.page ? item.page.isSystem : null;
        this.menu.push(
          new MenuItem({
            label: label,
            id: item.id,
            url: slug,
            isSystem: isSystem,
            children: children,
          })
        );
      }
    });
    this.setNewMenu();
    this.refreshActiveMenu();
  }
  setNewMenu() {
    this.menu$.next(this.menu);
  }
  refreshActiveMenu(activeSlug?) {
    let newMenu = this.menu[0]?.children;
    this.menu.forEach((item, index) => {
      item.children.forEach((child) => {
        if (child.url == activeSlug) {
          newMenu = this.menu[index].children;
        }
      });
    });
    this.activeSubMenu$.next(newMenu);
  }
}
