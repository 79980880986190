<router-outlet></router-outlet>
<footer class="container-fluid pt-5 bg-11 px-0">
  <div class="container pb-3">
    <!-- <p class="text-center c-second">Obtenir les dernières nouvelles de nous</p>
    <div class="text-center">
      <input
        type="text"
        class="footer-input"
        placeholder="Entrer votre email"
      />
    </div> -->
    <hr />
    <div class="row c-second text-center">
      <div class="col-md-4 col-lg-3 ml-auto py-3">
        <a href="https://www.enssup.gov.ma/fr.html" target="_blank">
          <img src="/assets/images/footer-flag.svg" alt="" />
        </a>
      </div>
      <div class="col-md-4 col-lg-3 mr-auto my-auto py-3">
        <a
          href="https://eeas.europa.eu/delegations/morocco_fr"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/assets/images/footer-flag2.svg" alt="" />
        </a>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-sm-6 col-md-4 col-lg-4 py-3">
        <img src="/assets/images/Flag-1.svg" alt="" />
        <p class="small-size mt-3 pr-2">
          Le Bureau National Erasmus+ a pour objectif la promotion et la mise en
          oeuvre effective du programme de l'Union européenne pour l'éducation,
          la formation, la jeunesse et le sport, Erasmus+ au Maroc .
        </p>
        <h3>SUIVEZ-NOUS</h3>
        <div class="social">
          <a target="_blank" [href]="socialLinks.FB">
            <img src="/assets/icons/facebook-1.svg" alt="" />
          </a>
          <a target="_blank" [href]="socialLinks.TWITTER">
            <img src="/assets/icons/twitter.svg" alt="" />
          </a>
          <a target="_blank" [href]="socialLinks.YOUTUBE">
            <img src="/assets/icons/youtube-play.svg" alt="" />
          </a>
        </div>
      </div>
      <div class="col-sm-6 col-md-4 col-lg-4 py-3">
        <h3>liens</h3>
        <div class="pr-2">
          <li *ngFor="let link of menuItems">
            <img
              src="/assets/icons/arrow-down-b-4.svg"
              alt=""
              class="arrow-right"
            />
            <a [routerLink]="[link.url]">{{ link.text | translate }}</a>
          </li>
        </div>
      </div>
      <!-- <div class="col-sm-6 col-md-4 col-lg-3 py-3">
        <h3>Mon compte</h3>
        <div class="pr-2">
          <li *ngFor="let item of myProfile">
            <img
              src="/assets/icons/arrow-down-b-4.svg"
              alt=""
              class="arrow-right"
            />
            <a [routerLink]="[item.url]">{{ item.text | translate }}</a>
          </li>
        </div>
      </div> -->
      <div class="col-sm-6 col-md-4 col-lg-4 py-3">
        <h3>contact info</h3>
        <div class="location">
          <p class="mb-0">
            <img src="/assets/icons/map-marker-line.svg" alt="" />
            24, AV Omar Ibn Alkhattab, app 13, 4 ème étage, Agdal Rabat
          </p>
          <p class="mb-0">
            <img src="/assets/icons/telephone.svg" alt="" />
            <a href="tel:+212 000 000 000">+212 808 507 688</a>
          </p>
          <p class="mb-0">
            <img src="/assets/icons/email.svg" alt="" />
            <a href="mailto:contact@erasmusplus.ma">contact@erasmusplus.ma</a>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid bg-12">
    <div class="container">
      <div class="row text-center">
        <div class="col-12 py-3">
          <p class="mb-0 text-center">
            Copyright &copy; {{ year }} Erasmus. All Right reserved , developed
            by
            <a class="c-primary" href="https://invyscode.co" target="_balnk"
              ><strong>invyscode</strong></a
            >
          </p>
        </div>
        <!-- <div class="mx-auto py-3 text-center">
          <a href="">item one </a> | <a href="">item 2 </a> |<a href=""
            >item therer sljfla </a
          >| <a href="">item slernsfsl</a>
        </div> -->
      </div>
    </div>
  </div>
</footer>
