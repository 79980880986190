import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, retry, take, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ValidationError } from '../../models';
import { ToastrNotificationsService } from '../adaptors/toastr-notifications.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService implements OnDestroy {
  apiUrl = environment.api.baseUrl;
  notifier = new Subject();
  defautErrorMessage: string =
    "Une erreur s'est produite, veuillez réessayer plus tard";
  constructor(
    private httpClient: HttpClient,
    private toastr: ToastrNotificationsService
  ) {}

  // httpOptions = {
  //   headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  // };

  /**
   * post
   * @param path
   * @param body
   */
  post(path: string, body?, httpOptions?): Observable<any> {
    return this.httpClient.post(this.apiUrl + path, body, httpOptions).pipe(
      takeUntil(this.notifier),
      retry(2),
      catchError((error: HttpErrorResponse) => this.handleError(error))
    );
  }

  /**
   * get
   * @param path include id in the path if u wnat to get only one item
   */
  get(path: string, params?: HttpParams): Observable<any> {
    let option = {};
    if (params) {
      option = { params };
    }
    return this.httpClient.get(this.apiUrl + path, option).pipe(
      takeUntil(this.notifier),
      retry(2),
      catchError((error: HttpErrorResponse) => this.handleError(error))
    );
  }

  /**
   * update
   * @param path
   * @param body
   */
  put(path: string, body, httpOptions?): Observable<any> {
    return this.httpClient.put(this.apiUrl + path, body, httpOptions).pipe(
      takeUntil(this.notifier),
      retry(2),
      catchError((error: HttpErrorResponse) => this.handleError(error))
    );
  }

  /**
   * delet
   * @param path include id in the path to delete something
   */
  delete(path: string, httpOptions?): Observable<any> {
    return this.httpClient.delete(this.apiUrl + path, httpOptions).pipe(
      takeUntil(this.notifier),
      retry(2),
      catchError((error: HttpErrorResponse) => this.handleError(error))
    );
  }

  private handleError(error: HttpErrorResponse) {
    // let errorMessage = '';
    let errorMessage: ValidationError;

    if (error.error instanceof ErrorEvent) {
      //error client
      // errorMessage = error.error.message;
      errorMessage.message = error.error.message;
    } else {
      //error server
      errorMessage = {
        message: error.message,
      };
      // `error status: ${error.status}, ` + `message: ${error.message}`;
    }
    // this.toastr.error({ message: errorMessage.message });
    this.toastr.error({
      message: error.error.message || this.defautErrorMessage,
    });

    // return throwError(errorMessage);
    return throwError(
      error.error.message !== undefined
        ? error.error
        : { message: this.defautErrorMessage }
    );
  }
  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
    console.log('successfully unsubscribed');
  }
}
