import { Router } from '@angular/router';
import { RoutesEnum } from '../models';

export abstract class Redirection {
  static router: Router;

  /**
   * navigate to dashboard
   */
  static dashboard() {
    Redirection.router.navigate([RoutesEnum.DASHBOARD]);
  }
  static updateEstablishment() {
    Redirection.router.navigate([RoutesEnum.ESTABLISHMENTUPDATE]);
  }
  static login() {
    Redirection.router.navigate([RoutesEnum.LOGIN]);
  }
  static notFound() {
    Redirection.router.navigate([RoutesEnum.NOTFOUND]);
  }

  /**
   * go to a certain route
   * @param path
   */
  static custom(path: string) {
    Redirection.router.navigate([path]);
  }
}
