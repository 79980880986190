import { Component, HostListener, OnInit } from '@angular/core';
import { RoutesEnum, User } from './core/models';
import { RoutingStateService } from './core/services/global/routing-state.service';
import { LoaderService } from 'src/app/core/services/global/loader.service';

import {
  Router,
  // import as RouterEvent to avoid confusion with the DOM Event
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from '@angular/router';
import { MenuItem, subMenuItem } from './core/models/interfaces/menu-items';
import { SocialMedia } from './core/models/enums/social-media.enum';
import { Observable } from 'rxjs';
// import { SubMeunus } from './core/helpers/sub-meuns';
import { delay, share } from 'rxjs/operators';
import { AuthService } from './core/services/global/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public routes: typeof RoutesEnum = RoutesEnum;

  title = 'erasmus-front-office';
  httpLoading: boolean;
  routeLoading: boolean;
  httploading$: Observable<boolean>;
  menuItems: MenuItem[];
  singleLinksMenuItems: subMenuItem[];
  public isMenuCollapsed = true;
  public showMobileNav = false;
  object: any;
  socialLinks: typeof SocialMedia = SocialMedia;
  user$: Observable<User>;
  user: User = null;
  constructor(
    private router: Router,
    private routingState: RoutingStateService,
    private loaderService: LoaderService,
    // private subMenus: SubMeunus,
    private authService: AuthService
  ) {
    this.routingState.loadRouting();
    this.router.events.subscribe((e: RouterEvent) => {
      this.navigationInterceptor(e);
    });
  }

  ngOnInit(): void {
    /**
     * fetching current user if it existes
     */
    this.authService
      .getCurrentUser()
      .pipe(share())
      .subscribe((user) => (this.user = user));

    /**
     * subscribing to loader service
     */
    this.loaderService
      .httpProgress()
      .pipe(delay(0))
      .subscribe((state) => (this.httpLoading = state));

    /**
     * getting menu items
     */
    // this.subMenus.getLinks().subscribe((data: MenuItem[]) => {
    //   this.menuItems = data;
    // });
    // this.subMenus.getSingleLinks().subscribe((data: subMenuItem[]) => {
    //   this.singleLinksMenuItems = data;
    // });
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.routeLoading = true;
    }
    if (event instanceof NavigationEnd) {
      this.routeLoading = false;
    }

    // Set routeLoading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.routeLoading = false;
    }
    if (event instanceof NavigationError) {
      this.routeLoading = false;
    }
  }
  /* adding class when scrolling */
  @HostListener('window:scroll', [])
  onWindowScroll(event: Event) {
    //set up the div "id=nav"
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById('menu-nav').classList.add('scrolled');
    } else {
      document.getElementById('menu-nav').classList.remove('scrolled');
    }
  }
  logout() {
    this.authService.logout();
  }
}
