import { Injectable } from '@angular/core';
import { ToastrNotificationsService } from '../adaptors/toastr-notifications.service';
import { ApiService } from '../global';
import { AuthService } from '../global/auth.service';

@Injectable({
  providedIn: 'root',
})
export class EstablishmentService {
  university_id: number;
  constructor(
    private apiService: ApiService,
    private authService: AuthService
  ) {
    this.authService.user$.subscribe((user) => {
      this.university_id = user?.university_id;
    });
  }
  get() {
    console.log(this.university_id);
    return this.apiService.get('/universities/' + this.university_id);
  }
  /**
   * update functioin
   * @param data
   */
  update(data) {
    return this.apiService.put('/universities/' + this.university_id, data);
  }
  post(data) {
    return this.apiService.post('/universities/' + this.university_id, data);
  }
}
