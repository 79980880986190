import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DotsData } from 'ngx-owl-carousel-o/lib/models/navigation-data.models';
import { RoutesEnum } from 'src/app/core/models';
import { ApiService } from 'src/app/core/services';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-download-new-file',
  templateUrl: './download-new-file.component.html',
  styleUrls: ['./download-new-file.component.scss'],
})
export class DownloadNewFileComponent implements OnInit {
  fileIsReady: boolean = false;
  fileName: string = 'no file is chosen';
  activePages = [true, false, false];
  excelData = [];
  maxNumber: number = 3;
  fieldsForm: FormGroup;
  fields = [];
  previwData = [];
  @Input() public data;
  details = {
    icm_tools: {
      endPoint: '/excel-file',
      title: 'ICM outils',
      listingUrl: 'DASHBOARD',
    },
    icm_appreved: {
      endPoint: '/import_icm_approved_user',
      title: 'ICM approuvé',
      listingUrl: 'DASHBOARD',
    },
    emjmd_user: {
      endPoint: '/import_emjmd_user',
      title: 'EMJMD',
      listingUrl: 'DASHBOARD',
    },
    cbhe_user: {
      endPoint: '/import_cbhe_user',
      title: 'CBHE',
      listingUrl: 'DASHBOARD',
    },
    jean_monet_user: {
      endPoint: '/import_jean_monet_user',
      title: 'JEAN MONNET',
      listingUrl: 'DASHBOARD',
    },
  };
  title: string;
  uploadForm: FormGroup;
  statusMessage: string;
  loading: boolean = false;
  constructor(
    private activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private apiService: ApiService,
    public route: Router
  ) {}
  ngOnInit(): void {
    this.initForm();
    console.log(this.data);
    if (this.data) {
      this.title = this.details[this.data].title;
    }
  }
  // initForm() {
  //   this.fieldsForm = this.fb.group({
  //     field1: [null, [Validators.required]],
  //     field2: [null, Validators.required],
  //     field3: [null, Validators.required],
  //   });
  // }
  initForm(): void {
    this.uploadForm = this.fb.group({
      file: ['', Validators.required],
    });
  }
  updateFieldsForm(index, val) {
    switch (index) {
      case 0:
        this.fieldsForm.patchValue({ field1: val });
        break;
      case 1:
        this.fieldsForm.patchValue({ field2: val });
        break;
      case 2:
        this.fieldsForm.patchValue({ field3: val });
        break;

      default:
        break;
    }
  }

  close() {
    this.activeModal.close('closed');
  }
  activatePage(pageNumber: number) {
    this.activePages.fill(false);
    this.activePages[pageNumber] = true;
  }

  // onFileChange(ev) {
  //   let workBook = null;
  //   let jsonData = null;
  //   const reader = new FileReader();
  //   const file = ev.target.files[0];
  //   this.fileName = file.name;
  //   this.fileIsReady = true;
  //   reader.onload = (event) => {
  //     const data = reader.result;
  //     workBook = XLSX.read(data, { type: 'binary' });
  //     jsonData = workBook.SheetNames.reduce((initial, name) => {
  //       const sheet = workBook.Sheets[name];
  //       initial[name] = XLSX.utils.sheet_to_json(sheet);
  //       return initial;
  //     }, {});
  //     this.excelData = jsonData.Sheet1.slice(0, this.maxNumber);
  //     jsonData = null;
  //     console.log(this.excelData);
  //     this.setFields();
  //   };
  //   reader.readAsBinaryString(file);
  // }

  // onFileChange(ev) {
  //   let workBook = null;
  //   let jsonData = null;
  //   const reader = new FileReader();
  //   const file = ev.target.files[0];
  //   this.fileName = file.name;
  //   this.fileIsReady = true;
  //   this.uploadForm.get('file').setValue(file);
  //   // this.isSelected = true;
  //   reader.onload = (event) => {
  //     const data = reader.result;
  //     workBook = XLSX.read(data, { type: 'binary' });
  //     jsonData = workBook.SheetNames.reduce((initial, name) => {
  //       const sheet = workBook.Sheets[name];
  //       initial[name] = XLSX.utils.sheet_to_json(sheet);
  //       return initial;
  //     }, {});
  //     this.excelData = jsonData.Worksheet.slice(0, this.maxNumber);
  //     jsonData = null;
  //     console.log(this.excelData);
  //   };
  //   reader.readAsBinaryString(file);
  // }

  onFileChange(ev) {
    // console.log('file change start');
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    this.fileName = file.name;
    this.fileIsReady = true;
    this.uploadForm.get('file').setValue(file);

    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, {
        type: 'binary',
        cellDates: true,
        dateNF: 'mm/dd/yyyy',
      });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet, {
          defval: '',
          raw: false,
        });
        return initial;
      }, {});
      console.log('json data', jsonData.Sheet1);
      this.excelData = jsonData.Sheet1;
      // this.factorDataToSend(jsonData.Sheet1);
      // this.excelData = jsonData.Worksheet.slice(0, this.maxNumber);
      jsonData = null;
      // console.log(this.excelData);
    };
    reader.readAsBinaryString(file);
  }

  setFields() {
    this.fields = Object.keys(this.excelData[0]);
    for (let i = 0; i < 3; i++) {
      this.setData(i, this.fields[0]);
    }
    console.log(this.fields);
  }
  onChange(event) {
    let val = event.target.value;
    let elemetNumber = parseInt(event.target.getAttribute('number'));
    this.setData(elemetNumber, val);
    this.updateFieldsForm(elemetNumber, val);
    console.log(val);
    console.log(elemetNumber);
    console.log(this.fieldsForm.value);
  }

  /**
   * set data
   * @param index
   * @param key
   */
  setData(index: number, key: string) {
    let dataToSet = this.excelData.map((value) => value[key]);
    this.previwData[index] = dataToSet;
  }

  upload() {
    // return;
    this.activatePage(2);
    this.onSubmit();
  }

  onSubmit() {
    this.statusMessage = 'loading...';
    const formData = new FormData();
    formData.append('file', this.uploadForm.get('file').value);
    // this.apiService.post(this.details[this.data].endPoint, formData).subscribe(
    this.apiService
      .post(this.details[this.data].endPoint, this.excelData)
      .subscribe(
        (res) => {
          console.log(res);
          this.statusMessage = 'téléchargé avec succès';
        },
        (err) => {
          console.log(err);
          this.statusMessage =
            'un problème est survenu, veuillez réessayer plus tard';
        }
      );
  }
  goBack() {
    // this.dialogRef.close(true);
    this.close();
    this.route.navigateByUrl(RoutesEnum[this.details[this.data].listingUrl]);
  }
}
