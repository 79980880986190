export class MenuItem {
  private _id: number;
  private _label: string;
  private _url: string;
  private _isSystem: boolean;

  private _children: SubMenuItem[];

  constructor(item?: any) {
    this.id = item.id;
    this.label = item.label;
    this.url = item.url;
    this.children = item.children;
    this.isSystem = item.isSystem;
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get label(): string {
    return this._label;
  }

  public set label(label: string) {
    this._label = label;
  }

  public get url(): string {
    return this._url;
  }

  public set url(url: string) {
    this._url = url;
  }

  public get children(): SubMenuItem[] {
    return this._children;
  }

  public set children(children: SubMenuItem[]) {
    this._children = children;
  }
  public get isSystem(): boolean {
    return this._isSystem;
  }

  public set isSystem(isSystem: boolean) {
    this._isSystem = isSystem;
  }
}
export class SubMenuItem {
  private _id: number;
  private _label: string;
  private _url: string;
  private _isSystem: boolean;

  constructor(item?: any) {
    this.id = item.id;
    this.label = item.label;
    this.url = item.url;
    this.isSystem = item.isSystem;
  }

  public get id(): number {
    return this._id;
  }

  public set id(id: number) {
    this._id = id;
  }

  public get label(): string {
    return this._label;
  }

  public set label(label: string) {
    this._label = label;
  }

  public get url(): string {
    return this._url;
  }

  public set url(url: string) {
    this._url = url;
  }
  public get isSystem(): boolean {
    return this._isSystem;
  }

  public set isSystem(isSystem: boolean) {
    this._isSystem = isSystem;
  }
}
