import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

export interface toastrParams {
  message: string;
  title?: string;
  ToastConfig?: object;
}
@Injectable({
  providedIn: 'root',
})
export class ToastrNotificationsService {
  constructor(private ts: ToastrService) {}

  /**
   * succes message
   * @param details   is an object {message : string, title? : string , toastConfig? : object}
   */
  success(details: toastrParams) {
    this.ts.success(details.message, details.title, details.ToastConfig);
  }

  /**
   * error message
   * @param details   is an object {message : string, title? : string , toastConfig? : object}
   */
  error(details: toastrParams) {
    this.ts.error(details.message, details.title, details.ToastConfig);
  }

  /**
   * info message
   * @param details   is an object {message : string, title? : string , toastConfig? : object}
   */
  info(details: toastrParams) {
    this.ts.info(details.message, details.title, details.ToastConfig);
  }

  /**
   * warning message
   * @param details   is an object {message : string, title? : string , toastConfig? : object}
   */
  warning(details: toastrParams) {
    this.ts.warning(details.message, details.title, details.ToastConfig);
  }
}
