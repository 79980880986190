<div class="container-fluid pb-5 px-0">
  <div class="container">
    <div class="col-md-8 col-lg-6 mx-auto px-0">
      <div class="establishment-overview-container" *ngIf="isReady">
        <div class="text-center">
          <img
            class="logo"
            [src]="establishment.logo | baseUrl: 'apiUrl'"
            alt=""
          />
        </div>
        <h2 class="text-center mb-5 mt-4">{{ establishment.name }}</h2>
        <div class="row mb-4">
          <div class="col-md-6 py-2">
            <div class="inner-container">
              <img src="/assets/icons/icon-book.svg" alt="" />
              <div>
                <h3 class="light">cours</h3>
                <span class="number">{{ establishment.lesson.length }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-6 py-2">
            <div class="inner-container">
              <img src="/assets/icons/icon-felieres.svg" alt="" />
              <div>
                <h3 class="light">offres de formation</h3>
                <span class="number">{{
                  establishment.trainingOffer.length
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <p class="mt-4">
          <img src="/assets/icons/map-marker-2.svg" alt="" />
          <strong>adresse : </strong>
          <span>{{ establishment.address }}</span>
        </p>

        <p>
          <img src="/assets/icons/globe-alt.svg" alt="" />
          <strong>Site web : </strong>
          <span
            ><a
              [href]="establishment.webSite"
              target="_blank"
              class="clean-a c-dark"
              >{{ establishment.webSite }}
            </a></span
          >
        </p>
        <p>
          <img src="/assets/icons/facebook.svg" alt="" />
          <strong>Facebook : </strong>
          <span
            ><a
              [href]="establishment.facebook"
              target="_blank"
              class="clean-a c-dark"
              >{{ establishment.facebook }}
            </a></span
          >
        </p>
        <p>
          <!-- <img src="/assets/icons/google.svg" alt="" /> -->
          <i class="fab fa-linkedin mr-3"></i>
          <strong>Linkedin : </strong>
          <span
            ><a
              [href]="establishment.linkedin"
              target="_blank"
              class="clean-a c-dark"
              >{{ establishment.linkedin }}</a
            ></span
          >
        </p>
        <div class="btn-holder mt-3">
          <a [routerLink]="[routes.ESTABLISHMENTUPDATE]" class="my-linear-btn">
            <img src="/assets/icons/update-arrows.svg" alt="" />
            Mettre à jours mes donées</a
          >
        </div>

        <div class="btn-holder">
          <a
            target="_blank"
            [href]="[
              'export_universities/' + establishment.id | baseUrl: 'apiUrl'
            ]"
            class="my-linear-btn"
          >
            <img src="/assets/icons/download.svg" alt="" />
            Télécharger la fiche</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
