import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toBase64',
})
export class ToBase64Pipe implements PipeTransform {
  // val: string;
  transform(value: any, ...args: unknown[]) {
    //  this.toBase64(value).then((res: string) => {
    //    this.val = res;
    // })
    return this.toBase64(value);
  }

  private toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
}
