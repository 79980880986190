import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-p-button',
  template: `
    <a class="p-button" [routerLink]="[routerLink]"
      ><ng-content></ng-content>
      <span class="after-button">
        <img src="/assets/icons/ios-arrow-thin-right.svg" alt="" />
      </span>
    </a>
  `,
})
export class PButtonComponent implements OnInit {
  @Input() routerLink: string;
  constructor() {}

  ngOnInit(): void {}
}
