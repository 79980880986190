import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HotTableModule } from '@handsontable/angular';

import { TranslateModule } from '@ngx-translate/core';
import {
  PageNotFoundComponent,
  PButtonComponent,
  PreviousPageComponent,
  SubMenuComponent,
  ImportantLinksComponent,
  DownloadNewFileComponent,
  FileImageComponent,
} from './components';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { TruncatePipe } from '../core/helpers/truncate.pipe';
import { EstablishmentOverviewComponent } from '../pages/establishment/components';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BaseUrlPipe } from '../core/helpers/base-url.pipe';
import { NavigatePipe } from '../core/helpers/navigate.pipe';
import { ReversePipe } from '../core/helpers/reverse.pipe';
import { EnumToArrayPipe } from '../core/helpers/enum-to-array.pipe';
import { ToBase64Pipe } from '../core/helpers/to-base-64.pipe';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SimpleCardComponent } from './components/simple-card/simple-card.component';
import { HomeNewsCardComponent } from './components/home-news-card/home-news-card.component';

@NgModule({
  declarations: [
    PButtonComponent,
    TruncatePipe,
    PreviousPageComponent,
    EstablishmentOverviewComponent,
    PageNotFoundComponent,
    SubMenuComponent,
    ImportantLinksComponent,
    DownloadNewFileComponent,
    BaseUrlPipe,
    NavigatePipe,
    ReversePipe,
    EnumToArrayPipe,
    FileImageComponent,
    ToBase64Pipe,
    MainMenuComponent,
    SafeHtmlPipe,
    SimpleCardComponent,
    HomeNewsCardComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    NgbModule,
    RouterModule,
    NgxDatatableModule,
    CarouselModule,
    HotTableModule,
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    NgbModule,
    RouterModule,
    PButtonComponent,
    TruncatePipe,
    PreviousPageComponent,
    EstablishmentOverviewComponent,
    PageNotFoundComponent,
    NgxDatatableModule,
    CarouselModule,
    SubMenuComponent,
    ImportantLinksComponent,
    DownloadNewFileComponent,
    BaseUrlPipe,
    NavigatePipe,
    ReversePipe,
    EnumToArrayPipe,
    FileImageComponent,
    ToBase64Pipe,
    HotTableModule,
    MainMenuComponent,
    SafeHtmlPipe,
    SimpleCardComponent,
  ],
})
export class SharedModule {}
