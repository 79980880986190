import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { DynamicMenuService } from 'src/app/core/helpers/dynamic-menu.service';
import { RoutesEnum } from 'src/app/core/models';
import { MenuItem } from 'src/app/core/models/classes/menuItem';
import { User } from 'src/app/core/models';
import { AuthService } from 'src/app/core/services/global/auth.service';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit {
  public routes: typeof RoutesEnum = RoutesEnum;

  menuItems: MenuItem[] = [];
  public isMenuCollapsed = true;
  public showMobileNav = false;
  routesEnum: typeof RoutesEnum = RoutesEnum;

  user$: Observable<User>;
  user: User = null;

  constructor(
    private menuService: DynamicMenuService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    /**
     * fetching current user if it existes
     */
    this.authService
      .getCurrentUser()
      .pipe(share())
      .subscribe((user) => (this.user = user));

    this.menuService.menu$.subscribe((data) => {
      this.menuItems = data;
    });
    this.menuService.getMenu().subscribe();
  }
  constructRouterLink(item: MenuItem) {
    if (!item.children.length && item.isSystem) {
      return '/' + item.url;
    } else if (item.children.length || !item.isSystem) {
      if (item.children.length) {
        return null;
      }
      if (!item.isSystem) {
        return '/' + this.routesEnum.PAGE + '/' + item.url;
      }
    }
  }
  constructSubLink(item) {
    if (item.url.includes('https:/')) {
      return item.url;
    }
    if (!item.isSystem) {
      return '/' + this.routesEnum.PAGE + '/' + item.url;
    }
  }
  isExternal(item) {
    if (item.url.includes('https:/')) {
      return true;
    } else {
      return false;
    }
  }
  navigate(url) {
    window.open(url, '_blank');
  }
  logout() {
    this.authService.logout();
  }
}
