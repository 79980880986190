import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicMenuService } from 'src/app/core/helpers/dynamic-menu.service';
import { RoutesEnum } from 'src/app/core/models';
import { SubMenuItem } from 'src/app/core/models/classes/menuItem';

@Component({
  selector: 'app-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss'],
})
export class SubMenuComponent implements OnInit {
  @Input() activeModule;
  public routesEnum: typeof RoutesEnum = RoutesEnum;
  links: SubMenuItem[];
  constructor(
    private router: Router,
    location: Location,

    private menuService: DynamicMenuService
  ) {
    router.events.subscribe((val) => {
      let activeSlug = location.path().substring(1);
      console.log(activeSlug);
      this.menuService.refreshActiveMenu(activeSlug);
    });
  }

  ngOnInit(): void {
    this.menuService.activeSubMenu$.subscribe((data) => {
      this.links = data;
    });
  }
  constructRouterLink(item: SubMenuItem) {
    if (item.isSystem) {
      return '/' + item.url;
    } else {
      return '/' + this.routesEnum.PAGE + '/' + item.url;
    }
  }
}
