import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ObjectToFormDataService {
  constructor() {}

  /**
   * build form data
   * @param formData
   * @param data
   * @param parentKey
   */
  buildFormData(formData, data, parentKey?) {
    if (
      data &&
      typeof data === 'object' &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        this.buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else {
      const value = data == null ? '' : data;

      formData.append(parentKey, value);
    }
  }

  /**
   * json to form data
   * @param data
   * @returns
   */
  jsonToFormData(data) {
    const formData = new FormData();

    this.buildFormData(formData, data);

    return formData;
  }
}
