import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ObjectToFormDataService } from 'src/app/core/helpers/object-to-form-data.service';
import { Function } from 'src/app/core/models/enums/function.enum';
import { ToastrNotificationsService } from 'src/app/core/services/adaptors/toastr-notifications.service';
import { EstablishmentService } from 'src/app/core/services/crud/establishment.service';

@Component({
  selector: 'app-establishment-update',
  templateUrl: './establishment-update.component.html',
  styleUrls: ['./establishment-update.component.scss'],
})
export class EstablishmentUpdateComponent implements OnInit {
  image;
  updateForm: FormGroup;
  icmContacts: FormArray;
  internationalRelations: FormArray;
  contactCBHE: FormArray;
  contactJM: FormArray;
  lessons: FormArray;
  trainingOffer: FormArray;
  trainingOffers: FormArray;
  functionEnum: typeof Function = Function;
  disableButtons: boolean = true;
  constructor(
    private fb: FormBuilder,
    private establishmentService: EstablishmentService,
    private objectToFomData: ObjectToFormDataService,
    private notify: ToastrNotificationsService
  ) {
    this.initForm();
  }
  ngOnInit(): void {
    this.f.disable();
    this.updateForm.patchValue({});
    this.establishmentService.get().subscribe((data) => {
      this.afterReadOne(data);
      this.updateForm.patchValue(data);
    });
  }

  /**
   * init form update
   */
  initForm(): void {
    this.updateForm = this.fb.group({
      id: [null, Validators.required],
      address: [null, Validators.required],
      linkedin: [null, Validators.required],
      name: [null, Validators.required],
      numberOfErasmusStudents: [null],
      numberOfErasmusTeachers: [null],
      numberOfInternationalStudents: [null],
      numberOfSectors: [null],
      numberOfStudents: [null],
      numberOfTeachers: [null],
      status: [null],
      universityNumber: [null],
      webSite: [null, Validators.required],
      facebook: [null, Validators.required],
      instagram: [null, Validators.required],
      geographicalLocalisation: [null, Validators.required],
      icmContacts: this.fb.array([this.createIcmContacts()]),
      internationalRelations: this.fb.array([
        this.createInternationalRelations(),
      ]),
      cbheContacts: this.fb.array([this.createContactCBHE()]),
      jeanMonetContacts: this.fb.array([this.createContactJM()]),
      trainingOffer: this.fb.array([this.createTrainingOffer()]),
      lesson: this.fb.array([this.createLessons()]),
    });
  }

  /**
   * get form update
   */
  get f() {
    return this.updateForm;
  }
  enableEditing() {
    this.f.enable();
    this.disableButtons = false;
  }

  // Icm form array
  /* ICM create */
  createIcmContacts(val?): FormGroup {
    return this.fb.group({
      id: [val?.id || null, Validators.required],
      fullName: [val?.fullName || null],
      phoneNumber: [val?.phoneNumber || null],
      email: [val?.email || null, [Validators.required, Validators.email]],
      contactFunction: [val?.contactFunction || null],
      universityId: [val?.universityId || null],
    });
  }
  /* ICM add */
  addIcmContacts(val?): void {
    this.icmContacts = this.updateForm.get('icmContacts') as FormArray;
    this.icmContacts.push(this.createIcmContacts(val));
  }

  /**
   * remove row of skill field
   * @param i
   */
  removeIcmContacts(i: number) {
    const control = this.updateForm.get('icmContacts') as FormArray;
    control.removeAt(i);
  }

  initializeIcmContacts(value) {
    const control = this.updateForm.get('icmContacts') as FormArray;
    control.reset();
    control.removeAt(0);
    let initialValues: [];
    initialValues = value.icmContacts;
    initialValues.forEach((value2) => {
      this.addIcmContacts(value2);
    });
  }

  // end of Icm form array
  // international relation array
  /* internationalRelations create */
  createInternationalRelations(val?): FormGroup {
    return this.fb.group({
      fullName: [val?.id || null, Validators.required],
      phoneNumber: [val?.phoneNumber || null, Validators.required],
      email: [val?.email || null, Validators.required],
    });
  }
  /* internationalRelations add */
  addInternationalRelations(val?): void {
    this.internationalRelations = this.updateForm.get(
      'internationalRelations'
    ) as FormArray;
    this.internationalRelations.push(this.createInternationalRelations(val));
  }
  /**
   * remove row of skill field
   * @param i
   */
  removeInternationalRelations(i: number) {
    const control = this.updateForm.get('internationalRelations') as FormArray;
    control.removeAt(i);
  }

  initializeInternationalRelations(value) {
    const control = this.updateForm.get('internationalRelations') as FormArray;
    control.reset();
    control.removeAt(0);
    let initialValues: [];
    initialValues = value.internationalRelations;
    initialValues.forEach((value2) => {
      this.addInternationalRelations(value2);
    });
  }
  // end of international relation array
  // cbhe array
  /* CBHE create */
  createContactCBHE(val?): FormGroup {
    return this.fb.group({
      fullName: [val?.fullName || null, Validators.required],
      phoneNumber: [val?.phoneNumber || null, Validators.required],
      email: [val?.email || null, [Validators.required, Validators.email]],
      contactFunction: [val?.contactFunction || null, Validators.required],
    });
  }
  /* CBHE add */
  addContactCBHE(val?): void {
    this.contactCBHE = this.updateForm.get('cbheContacts') as FormArray;
    this.contactCBHE.push(this.createContactCBHE(val));
  }
  /**
   * remove row of skill field
   * @param i
   */
  removeContactCBHE(i: number) {
    const control = this.updateForm.get('cbheContacts') as FormArray;
    control.removeAt(i);
  }

  initializeContactCBHE(value) {
    const control = this.updateForm.get('cbheContacts') as FormArray;
    control.reset();
    control.removeAt(0);
    let initialValues: [];
    initialValues = value.cbheContacts;
    initialValues.forEach((value2) => {
      this.addContactCBHE(value2);
    });
  }
  // end of cbhe array
  //jean motnnet array
  /* JM create */
  createContactJM(val?): FormGroup {
    return this.fb.group({
      fullName: [val?.fullName || null, Validators.required],
      phoneNumber: [val?.phoneNumber || null, Validators.required],
      email: [val?.email || null, [Validators.required, Validators.email]],
      contactFunction: [val?.contactFunction || null, Validators.required],
    });
  }
  /* JM add */
  addContactJM(val?): void {
    this.contactJM = this.updateForm.get('jeanMonetContacts') as FormArray;
    this.contactJM.push(this.createContactJM(val));
  }
  /**
   * remove row of skill field
   * @param i
   */
  removeContactJM(i: number) {
    const control = this.updateForm.get('jeanMonetContacts') as FormArray;
    control.removeAt(i);
  }

  initializeContactJM(value) {
    const control = this.updateForm.get('jeanMonetContacts') as FormArray;
    control.reset();
    control.removeAt(0);
    let initialValues: [];
    initialValues = value.jeanMonetContacts;
    initialValues.forEach((value2) => {
      this.addContactJM(value2);
    });
  }
  //end of jean motnnet array
  //lessons array
  /* TrainingOffer create */
  createTrainingOffer(val?): FormGroup {
    return this.fb.group({
      establishment: [val?.establishment || null, Validators.required],
      diploma: [val?.diploma || null, Validators.required],
    });
  }
  /* TrainingOffer add */
  addTrainingOffer(val?): void {
    this.trainingOffer = this.updateForm.get('trainingOffer') as FormArray;
    this.trainingOffer.push(this.createTrainingOffer(val));
  }
  /**
   * remove row of skill field
   * @param i
   */
  removeTrainingOffer(i: number) {
    const control = this.updateForm.get('trainingOffer') as FormArray;
    control.removeAt(i);
  }

  initializeTrainingOffer(value) {
    const control = this.updateForm.get('trainingOffer') as FormArray;
    control.reset();
    control.removeAt(0);
    let initialValues: [];
    initialValues = value.trainingOffer;
    initialValues.forEach((value2) => {
      this.addTrainingOffer(value2);
    });
  }
  //end of TrainingOffer array
  //training offer
  /* lessons create */
  createLessons(val?): FormGroup {
    return this.fb.group({
      establishment: [val?.establishment || null, Validators.required],
      diploma: [val?.diploma || null, Validators.required],
    });
  }
  /* lessons add */
  addLessons(val?): void {
    this.lessons = this.updateForm.get('lesson') as FormArray;
    this.lessons.push(this.createLessons(val));
  }
  /**
   * remove row of skill field
   * @param i
   */
  removeLessons(i: number) {
    const control = this.updateForm.get('lesson') as FormArray;
    control.removeAt(i);
  }

  initializeLessons(value) {
    const control = this.updateForm.get('lesson') as FormArray;
    control.reset();
    control.removeAt(0);
    let initialValues: [];
    initialValues = value.lesson;
    initialValues.forEach((value2) => {
      this.addLessons(value2);
    });
  }
  //end of training offer
  //training offfer array
  /* training offer create */
  createTrainingOffers(val?): FormGroup {
    return this.fb.group({
      establishment: [val?.establishment || null, Validators.required],
      diploma: [val?.diploma || null, Validators.required],
    });
  }
  /* training offer add */
  addTrainingOffers(val?): void {
    this.trainingOffers = this.updateForm.get('trainingOffer') as FormArray;
    this.trainingOffers.push(this.createTrainingOffers(val));
  }
  /**
   * remove row of skill field
   * @param i
   */
  removeTrainingOffers(i: number) {
    const control = this.updateForm.get('trainingOffer') as FormArray;
    control.removeAt(i);
  }
  initializeTrainingOffers(value) {
    const control = this.updateForm.get('trainingOffer') as FormArray;
    control.reset();
    control.removeAt(0);
    let initialValues: [];
    initialValues = value.trainingOffer;
    initialValues.forEach((value2) => {
      this.addTrainingOffers(value2);
    });
  }
  //end of training offfer array

  /**
   * on submit form update
   */
  onSubmit() {
    let value = this.f.value;
    this.beforeSave(value);
    // value = this.toFormData(value);
    value = this.objectToFomData.jsonToFormData(value);
    console.log(value);
    this.establishmentService.post(value).subscribe((res) => {
      this.notify.success({
        message: 'Modifier avec succès',
      });
    });
  }

  fileChanges(value): void {
    this.image = value;
  }

  afterReadOne(value: any) {
    this.image = { file: value.logo, type: 'server' };
    // adding to form arrays
    this.initializeIcmContacts(value);
    this.initializeInternationalRelations(value);
    this.initializeContactCBHE(value);
    this.initializeContactJM(value);
    this.initializeTrainingOffer(value);
    this.initializeLessons(value);
  }
  beforeSave(value: any) {
    value['logo'] = this.image.file;
  }

  // toFormData(item) {
  //   let formData = new FormData();

  //   for (var key in item) {
  //     if (item[key]) {
  //       formData.append(key, item[key]);
  //     }

  //   }
  //   return formData;
  // }
}
