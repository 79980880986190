import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-important-links',
  template: `
    <div class="important-links-container">
      <div *ngFor="let link of links">
        <a class="single-important-a" [routerLink]="[link.url]">
          <div class="single-important">
            {{ link.text }}
          </div>
        </a>
      </div>
    </div>
  `,
})
export class ImportantLinksComponent implements OnInit {
  links = [
    {
      text: 'Quoi ?',
      url: 'link',
    },
    {
      text: 'Qui ?',
      url: 'link',
    },
    {
      text: 'Comment ?',
      url: 'link',
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
