import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-news-card',
  templateUrl: './home-news-card.component.html',
  styleUrls: ['./home-news-card.component.scss'],
})
export class HomeNewsCardComponent implements OnInit {
  @Input() item;
  innerHtml;

  constructor() {}

  ngOnInit(): void {
    let finalData = JSON.parse(this.item.content);
    console.log(finalData);
    // this.innerHtml = finalData.chunks.body;
    this.innerHtml = finalData.html;
  }
}
