import { Component, OnInit, Inject, NgZone, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

// amCharts imports
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

@Component({
  selector: 'app-establishment-statistics',
  templateUrl: './establishment-statistics.component.html',
  styleUrls: ['./establishment-statistics.component.scss'],
})
export class EstablishmentStatisticsComponent implements OnInit {
  private chart: am4charts.XYChart;

  constructor(@Inject(PLATFORM_ID) private platformId, private zone: NgZone) {}

  ngOnInit(): void {}
  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }
  ngAfterViewInit() {
    // Chart code goes in here
    this.browserOnly(() => {
      am4core.useTheme(am4themes_animated);
      /* rounded chart */
      let chart = am4core.create('chartdiv', am4charts.PieChart);

      // Let's cut a hole in our Pie chart the size of 40% the radius
      chart.innerRadius = am4core.percent(70);
      chart.radius = am4core.percent(100);

      // Add data
      chart.data = [
        {
          country: 'Lithuania',
          litres: 501.9,
          bottles: 1500,
        },
        {
          country: 'Czechia',
          litres: 301.9,
          bottles: 990,
        },
        {
          country: 'Ireland',
          litres: 201.1,
          bottles: 785,
        },
        {
          country: 'Germany',
          litres: 165.8,
          bottles: 255,
        },
        {
          country: 'Australia',
          litres: 139.9,
          bottles: 452,
        },
        {
          country: 'Austria',
          litres: 128.3,
          bottles: 332,
        },
        {
          country: 'UK',
          litres: 99,
          bottles: 150,
        },
        {
          country: 'Belgium',
          litres: 60,
          bottles: 178,
        },
        {
          country: 'The Netherlands',
          litres: 50,
          bottles: 50,
        },
      ];

      // Add and configure Series
      var pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = 'litres';
      pieSeries.dataFields.category = 'country';

      pieSeries.slices.template.strokeWidth = 2;
      pieSeries.slices.template.strokeOpacity = 1;

      // Disabling labels and ticks on inner circle
      pieSeries.labels.template.disabled = true;
      pieSeries.ticks.template.disabled = false;

      // Disable sliding out of slices
      pieSeries.slices.template.states.getKey(
        'hover'
      ).properties.shiftRadius = 0;
      pieSeries.slices.template.states.getKey('hover').properties.scale = 0.9;
      /* chart 2 */
      let chart2 = am4core.create('chartdiv2', am4charts.PieChart);

      // Let's cut a hole in our Pie chart the size of 40% the radius
      chart2.innerRadius = am4core.percent(70);
      chart2.radius = am4core.percent(100);

      // Add data
      chart2.data = [
        {
          country: 'Lithuania',
          litres: 501.9,
          bottles: 1500,
        },
        {
          country: 'Czechia',
          litres: 301.9,
          bottles: 990,
        },
        {
          country: 'Ireland',
          litres: 201.1,
          bottles: 785,
        },
        {
          country: 'Germany',
          litres: 165.8,
          bottles: 255,
        },
        {
          country: 'Australia',
          litres: 139.9,
          bottles: 452,
        },
        {
          country: 'Austria',
          litres: 128.3,
          bottles: 332,
        },
        {
          country: 'UK',
          litres: 99,
          bottles: 150,
        },
        {
          country: 'Belgium',
          litres: 60,
          bottles: 178,
        },
        {
          country: 'The Netherlands',
          litres: 50,
          bottles: 50,
        },
      ];

      // Add and configure Series
      var pieSeries = chart2.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = 'litres';
      pieSeries.dataFields.category = 'country';

      pieSeries.slices.template.strokeWidth = 2;
      pieSeries.slices.template.strokeOpacity = 1;

      // Disabling labels and ticks on inner circle
      pieSeries.labels.template.disabled = true;
      pieSeries.ticks.template.disabled = false;

      // Disable sliding out of slices
      pieSeries.slices.template.states.getKey(
        'hover'
      ).properties.shiftRadius = 0;
      pieSeries.slices.template.states.getKey('hover').properties.scale = 0.9;
      /* column charts */

      let chartColumns = am4core.create('chartdivColumns', am4charts.XYChart);
      // Add data
      chartColumns.data = [
        {
          country: 'Austria',
          research: 128.3,
          marketing: 85,
          sales: 84,
        },
        {
          country: 'The Netherlands',
          research: 50,
          marketing: 42,
          sales: 25,
        },
      ];
      var categoryAxis = chartColumns.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = 'country';
      categoryAxis.title.text = 'Local country offices';
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 20;

      var valueAxis = chartColumns.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = 'Expenditure (M)';

      // Create series
      var series = chartColumns.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = 'research';
      series.dataFields.categoryX = 'country';
      series.name = 'Research';
      series.tooltipText = '{name}: [bold]{valueY}[/]';
      series.stacked = false;

      var series2 = chartColumns.series.push(new am4charts.ColumnSeries());
      series2.dataFields.valueY = 'marketing';
      series2.dataFields.categoryX = 'country';
      series2.name = 'Marketing';
      series2.tooltipText = '{name}: [bold]{valueY}[/]';
      series2.stacked = false;

      var series3 = chartColumns.series.push(new am4charts.ColumnSeries());
      series3.dataFields.valueY = 'sales';
      series3.dataFields.categoryX = 'country';
      series3.name = 'Sales';
      series3.tooltipText = '{name}: [bold]{valueY}[/]';
      series3.stacked = false;

      // Add cursor
      chartColumns.cursor = new am4charts.XYCursor();
    });
  }
  ngOnDestroy() {
    // Clean up chart when the component is removed
    this.browserOnly(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }
}
