<div class="container-fluid bg-light pb-3 px-0">
  <app-previous-page></app-previous-page>
  <div class="container">
    <h1 class="c-dark small-h1">
      <span class="c-primary mr-1">&#8226;</span
      >{{ "establishment.titles.t1" | translate }}
      <button
        class="float-right hover-pointer flat-btn"
        (click)="enableEditing()"
      >
        Modifier
      </button>
    </h1>
    <!-- <button [disabled]="!updateForm.enabled" (click)="addContactICM()">
      add contact
    </button>
    <button (click)="onSubmit()">submit form</button>
    <button (click)="enableEditing()">enable editing</button> -->
    <form [formGroup]="updateForm">
      <div class="update-container">
        <div class="row-container">
          <h4>informations générales</h4>
          <div>
            <div class="row">
              <div class="col-md-6 col-lg-3">
                <div class="form-group">
                  <label for="inputone">Nom de l'université :</label>
                  <input
                    type="text"
                    class="form-control"
                    id="inputone"
                    formControlName="name"
                    placeholder="Nom de l'université"
                  />
                </div>
              </div>

              <div class="col-md-6 col-lg-3">
                <div class="form-group">
                  <label for="inputone">Facebook :</label>
                  <input
                    type="text"
                    class="form-control"
                    id="inputone"
                    formControlName="facebook"
                    placeholder="Votre Nom"
                  />
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="form-group">
                  <label for="inputone">Instagram :</label>
                  <input
                    type="text"
                    class="form-control"
                    id="inputone"
                    formControlName="instagram"
                    placeholder="Votre Nom"
                  />
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="form-group">
                  <label for="inputone">Adresse : </label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="address"
                    id="inputone"
                    placeholder="Votre Nom"
                  />
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="form-group">
                  <label for="inputone">Logo : </label>
                  <app-file-image
                    [image]="image"
                    (changes)="fileChanges($event)"
                  ></app-file-image>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- icm contacts -->
        <div class="row-container">
          <h4>
            ICM contacts
            <button
              class="float-right flat-btn"
              (click)="addIcmContacts()"
              [disabled]="disableButtons"
            >
              ajouter
            </button>
          </h4>
          <div
            formArrayName="icmContacts"
            *ngFor="
              let item of updateForm.get('icmContacts')['controls'];
              let i = index
            "
          >
            <div [formGroupName]="i" class="row">
              <div class="col-md-6 col-lg-2">
                <div class="form-group">
                  <label for="inputone">Nom complet : </label>
                  <input
                    type="text"
                    class="form-control"
                    id="inputone"
                    formControlName="fullName"
                    placeholder="Nom complet"
                  />
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="form-group">
                  <label for="inputone">Numéro de téléphone :</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="phoneNumber"
                    id="inputone"
                    placeholder="Numéro de téléphone"
                  />
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="form-group">
                  <label for="inputone">Email :</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="email"
                    id="inputone"
                    placeholder="Email"
                  />
                </div>
              </div>
              <div class="col-md-6 col-lg-2">
                <div class="form-group">
                  <label for="inputone">Fonction :</label>
                  <!-- <input
                    type="text"
                    class="form-control"
                    formControlName="function"
                    id="inputone"
                    placeholder="Votre Nom"
                  />  -->
                  <select
                    name=""
                    id=""
                    formControlName="contactFunction"
                    class="form-control"
                  >
                    <option
                      *ngFor="let item of functionEnum | enumToArray: true"
                      [value]="item.key"
                    >
                      {{ item.key }}
                    </option>
                  </select>
                </div>
              </div>
              <div
                class="col-md-6 col-lg-2 mt-auto mb-3"
                *ngIf="updateForm.get('icmContacts')['controls'].length > 1"
              >
                <button
                  (click)="removeIcmContacts(i)"
                  type="button"
                  mat-flat-button
                  class="flat-btn-danger"
                  [disabled]="disableButtons"
                >
                  Supprimer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- end of icm contacts -->
        <!-- international relations -->
        <div class="row-container">
          <h4>
            Relations internationales
            <button
              class="float-right flat-btn"
              (click)="addInternationalRelations()"
              [disabled]="disableButtons"
            >
              ajouter
            </button>
          </h4>
          <div
            formArrayName="internationalRelations"
            *ngFor="
              let item of updateForm.get('internationalRelations')['controls'];
              let i = index
            "
          >
            <div [formGroupName]="i" class="row">
              <div class="col-md-6 col-lg-2">
                <div class="form-group">
                  <label for="inputone">Nom complet : </label>
                  <input
                    type="text"
                    class="form-control"
                    id="inputone"
                    formControlName="fullName"
                    placeholder="Nom complet"
                  />
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="form-group">
                  <label for="inputone">Numéro de téléphone :</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="phoneNumber"
                    id="inputone"
                    placeholder="Numéro de téléphone"
                  />
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="form-group">
                  <label for="inputone">Email :</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="email"
                    id="inputone"
                    placeholder="Email"
                  />
                </div>
              </div>
              <!-- <div class="col-md-6 col-lg-2">
                <div class="form-group">
                  <label for="inputone">Fonction :</label>
                
                  <select
                    name=""
                    id=""
                    formControlName="function"
                    class="form-control"
                  >
                    <option
                      *ngFor="let item of functionEnum | enumToArray: true"
                      [value]="item.key"
                    >
                      {{ item.key }}
                    </option>
                  </select>
                </div>
              </div> -->
              <div
                class="col-md-6 col-lg-2 mt-auto mb-3"
                *ngIf="
                  updateForm.get('internationalRelations')['controls'].length >
                  1
                "
              >
                <button
                  (click)="removeInternationalRelations(i)"
                  type="button"
                  mat-flat-button
                  class="flat-btn-danger"
                  [disabled]="disableButtons"
                >
                  Supprimer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- end of international relations -->
        <!-- cbhe Contacts -->
        <div class="row-container">
          <h4>
            CBHE contacts
            <button
              class="float-right flat-btn"
              (click)="addContactCBHE()"
              [disabled]="disableButtons"
            >
              ajouter
            </button>
          </h4>
          <div
            formArrayName="cbheContacts"
            *ngFor="
              let item of updateForm.get('cbheContacts')['controls'];
              let i = index
            "
          >
            <div [formGroupName]="i" class="row">
              <div class="col-md-6 col-lg-2">
                <div class="form-group">
                  <label for="inputone">Nom complet : </label>
                  <input
                    type="text"
                    class="form-control"
                    id="inputone"
                    formControlName="fullName"
                    placeholder="Nom complet"
                  />
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="form-group">
                  <label for="inputone">Numéro de téléphone :</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="phoneNumber"
                    id="inputone"
                    placeholder="Numéro de téléphone"
                  />
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="form-group">
                  <label for="inputone">Email :</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="email"
                    id="inputone"
                    placeholder="Email"
                  />
                </div>
              </div>
              <div class="col-md-6 col-lg-2">
                <div class="form-group">
                  <label for="inputone">Fonction :</label>

                  <select
                    name=""
                    id=""
                    formControlName="contactFunction"
                    class="form-control"
                  >
                    <option
                      *ngFor="let item of functionEnum | enumToArray: true"
                      [value]="item.key"
                    >
                      {{ item.key }}
                    </option>
                  </select>
                </div>
              </div>
              <div
                class="col-md-6 col-lg-2 mt-auto mb-3"
                *ngIf="updateForm.get('cbheContacts')['controls'].length > 1"
              >
                <button
                  (click)="removeContactCBHE(i)"
                  type="button"
                  mat-flat-button
                  class="flat-btn-danger"
                  [disabled]="disableButtons"
                >
                  Supprimer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- end of cbhe Contacts -->
        <!-- jeanMonet Contacts -->
        <div class="row-container">
          <h4>
            Jean Monnet contacts
            <button
              class="float-right flat-btn"
              (click)="addContactJM()"
              [disabled]="disableButtons"
            >
              ajouter
            </button>
          </h4>
          <div
            formArrayName="jeanMonetContacts"
            *ngFor="
              let item of updateForm.get('jeanMonetContacts')['controls'];
              let i = index
            "
          >
            <div [formGroupName]="i" class="row">
              <div class="col-md-6 col-lg-2">
                <div class="form-group">
                  <label for="inputone">Nom complet : </label>
                  <input
                    type="text"
                    class="form-control"
                    id="inputone"
                    formControlName="fullName"
                    placeholder="Nom complet"
                  />
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="form-group">
                  <label for="inputone">Numéro de téléphone :</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="phoneNumber"
                    id="inputone"
                    placeholder="Numéro de téléphone"
                  />
                </div>
              </div>
              <div class="col-md-6 col-lg-3">
                <div class="form-group">
                  <label for="inputone">Email :</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="email"
                    id="inputone"
                    placeholder="Email"
                  />
                </div>
              </div>
              <div class="col-md-6 col-lg-2">
                <div class="form-group">
                  <label for="inputone">Fonction :</label>

                  <select
                    name=""
                    id=""
                    formControlName="contactFunction"
                    class="form-control"
                  >
                    <option
                      *ngFor="let item of functionEnum | enumToArray: true"
                      [value]="item.key"
                    >
                      {{ item.key }}
                    </option>
                  </select>
                </div>
              </div>
              <div
                class="col-md-6 col-lg-2 mt-auto mb-3"
                *ngIf="
                  updateForm.get('jeanMonetContacts')['controls'].length > 1
                "
              >
                <button
                  (click)="removeContactJM(i)"
                  type="button"
                  mat-flat-button
                  class="flat-btn-danger"
                  [disabled]="disableButtons"
                >
                  Supprimer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- end of jeanMonet Contacts -->
        <!-- training offer -->
        <div class="row-container">
          <h4>
            Offres de formation
            <button
              class="float-right flat-btn"
              (click)="addTrainingOffer()"
              [disabled]="disableButtons"
            >
              ajouter
            </button>
          </h4>
          <div
            formArrayName="trainingOffer"
            *ngFor="
              let item of updateForm.get('trainingOffer')['controls'];
              let i = index
            "
          >
            <div [formGroupName]="i" class="row">
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label for="inputone">Etablissement : </label>
                  <input
                    type="text"
                    class="form-control"
                    id="inputone"
                    formControlName="establishment"
                    placeholder="Etablissement"
                  />
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label for="inputone">Diplôme :</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="diploma"
                    id="inputone"
                    placeholder="Diplôme"
                  />
                </div>
              </div>

              <div
                class="col-md-6 col-lg-2 mt-auto mb-3"
                *ngIf="updateForm.get('trainingOffer')['controls'].length > 1"
              >
                <button
                  (click)="removeTrainingOffer(i)"
                  type="button"
                  mat-flat-button
                  class="flat-btn-danger"
                  [disabled]="disableButtons"
                >
                  Supprimer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- end of training offer -->
        <!-- lessons -->
        <div class="row-container">
          <h4>
            cours
            <button
              class="float-right flat-btn"
              (click)="addLessons()"
              [disabled]="disableButtons"
            >
              ajouter
            </button>
          </h4>
          <div
            formArrayName="lesson"
            *ngFor="
              let item of updateForm.get('lesson')['controls'];
              let i = index
            "
          >
            <div [formGroupName]="i" class="row">
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label for="inputone">Etablissement : </label>
                  <input
                    type="text"
                    class="form-control"
                    id="inputone"
                    formControlName="establishment"
                    placeholder="Etablissement"
                  />
                </div>
              </div>
              <div class="col-md-6 col-lg-4">
                <div class="form-group">
                  <label for="inputone">Diplôme :</label>
                  <input
                    type="text"
                    class="form-control"
                    formControlName="diploma"
                    id="inputone"
                    placeholder="Diplôme"
                  />
                </div>
              </div>

              <div
                class="col-md-6 col-lg-2 mt-auto mb-3"
                *ngIf="updateForm.get('lesson')['controls'].length > 1"
              >
                <button
                  (click)="removeLessons(i)"
                  type="button"
                  mat-flat-button
                  class="flat-btn-danger"
                  [disabled]="disableButtons"
                >
                  Supprimer
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- end of lessons -->
        <div class="text-right">
          <button
            [disabled]="updateForm.pristine"
            (click)="onSubmit()"
            type="button"
            class="flat-btn"
          >
            Enregistrer
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
