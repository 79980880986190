export enum RoutesEnum {
  /* root routes */
  AUTH = '/my-space',
  HOME = '/',
  DASHBOARD = '/my-space/dashboard',
  STATISTICS = '/my-space/statistics',
  ESTABLISHMENT = '/my-space/establishment',
  ICM = '/my-space/icm',
  CJE = '/my-space/c-j-e',
  CBHE = '/my-space/c-b-h-e',
  ERASMUSNUMBERS = '/my-space/erasmus-numbers',
  RESOURCES = '/my-space/resourses',
  FORUM = '/my-space/forum',
  BESTPRACTICES = 'best-practices',
  DIFFICULTIES = 'difficulties',
  BESTPRACTICESLISTING = '/my-space/forum/best-practices',
  DIFFICULTIESLISTING = '/my-space/forum/difficulties',
  PROFILE = '/my-space/profile',
  JEANMONNET = '/my-space/jean-monnet',
  EMJMD = '/my-space/e-m-j-m-d',
  NOTFOUND = '404',
  LISTING = 'listing',
  NEWS = 'actualities',
  SINGLENEWS = 'actualities/:title',
  NEWSBASE = '/actualities/',
  ICMAPPROVED = 'icm-approved',
  ICMAPPROVEDLISTIN = '/my-space/icm/icm-approved',
  ICMTOOLS = 'icm-tools',
  ICMTOOLSLISTING = '/my-space/icm/icm-tools',
  UPLOADFILE = 'upload-file',
  UPLOADNEWFILE = '/my-space/upload-file',
  INLINEEDITING = 'inline-editing',
  ICMINLINEEDITING = '/my-space/icm/icm-tools/inline-editing',

  /* establishment routes */
  ESTABLISHMENTUPDATE = '/my-space/establishment/update',
  ESTABLISHMENTOVERVIEW = '/my-space/establishment/over-view',
  LOGIN = '/my-space/login',
  CONTACTUS = '/my-space/contact-us',
  FORGOTPASSWORD = '/my-space/forgot-password',
  CHANGEPASSWORD = '/my-space/change-password',

  /* national office routes */
  WHOAREWE = '/national-office/who-are-we',
  ACTIVITIES = '/national-office/activities',
  ACADEMY = '/national-office/academy',
  PUBLICATIONS = '/national-office/publications',
  PRESSARTICLES = '/national-office/press-articles',

  /* how to benefit routes */
  STUDENTDIPLOMA = '/how-to-benefit/student-diploma',
  STUDENT = '/how-to-benefit/student',
  DIPLOMA = '/how-to-benefit/graduate',
  COMPANYORASSOCIATION = '/how-to-benefit/company-or-association',
  HIGHEREDUCATIONINSTITUTION = '/how-to-benefit/higher-education-institution',
  PERSONALACAADM = '/how-to-benefit/personal-aca-adm',

  /* espace alumni routes */
  ESPACEALUMNI = '/espace-alumni',
  ENMANDDIREMED = '/espace-alumni/enm-and-iremed',
  ALUMNIRETREAT = '/espace-alumni/alumni-retreat',
  ALUMNIFACEBOOKLIVE = '/espace-alumni/alumni-facebook-live',
  ALUMNIENACTION = '/espace-alumni/alumni-en-action',
  ALUMNIASSOCIATION = '/espace-alumni/alumni-association',
  ALUMNIANDCOVID = '/espace-alumni/alumni-and-covid',

  /* partner search */
  PARTNERSEARCH = '/partner-search',
  PUBLICMOROCCANUNIVERSITIES = '/partner-search/public-moroccan-universities',
  PUBLICPRIVATEMOROCCANUNIVERSITIES = '/partner-search/public-private-moroccan-universities',

  /* reform experts */
  REFORMEXPERTS = '/reform-experts',
  REFORMEXPERTSACTIVITIES = '/reform-experts/activities',
  REFORMEXPERTSPRIORETIES = '/reform-experts/priorities',
  REFORMEXPERTSENSERGNEMENT = '/reform-experts/ensignement',

  /* erasmus in morocco */
  INTERNATIONALMOBILITY = '/erasmus-in-morocco/international-mobility',
  CAPACITYBUILDINGPROJECTS = '/erasmus-in-morocco/capacity-building-projects',
  PROJECTSNEWS = '/erasmus-in-morocco/projects-news',
  ERASMUSMUNDUSJOINT = '/erasmus-in-morocco/erasmus-mundus-joint',
  JEANMONNETERASMUSINMOROCCO = '/erasmus-in-morocco/jean-monnet-erasmus-in-morocco',
  INTRAAFTICAMOBILITY = '/erasmus-in-morocco/intra-africa-mobility',
  HORIZONEUORPE = '/erasmus-in-morocco/horizon-europe',
  TEMPUS = '/erasmus-in-morocco/tempus',

  /* dynamic */

  PAGE = 'page',
  PAGESLUG = 'page/:slug',
  PUBLICATIONSFILES = '/national-office/file',
}
