<div class="container-fluid pb-5 bg-10 padding-for-menu">
  <div class="container">
    <div class="row">
      <div class="col-md-3 pt-md-5 pt-2 pb-3">
        <app-sub-menu [activeModule]="'1'"></app-sub-menu>
      </div>
      <div
        *ngIf="page"
        class="col-md-9 pt-md-5 mt-md-5 national-office ck-content"
      >
        <!-- <h2>{{ page.title }}</h2> -->
        <div [innerHTML]="innerHtml | safeHtml"></div>
        <!-- <div #iframeContainer [style.height.px]="height">
          <iframe
            id="iframe"
            #iframe
            src="https://invyscode.co/entreprise.php"
            scrolling="no"
            frameborder="0"
            (load)="onload()"
            style="position: relative; width: 100%; height: 100%"
          ></iframe>
        </div> -->
      </div>
    </div>
  </div>
</div>
