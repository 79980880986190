import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SubMeunus } from 'src/app/core/helpers/sub-meuns';
import { SocialMedia } from 'src/app/core/models/enums/social-media.enum';
import { MenuItem } from 'src/app/core/models/interfaces/menu-items';
import { HttpClientService } from 'src/app/core/services/adaptors/http-client.service';
import { GlobalConstantsService } from 'src/app/core/services/global/global-constants.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  data;
  links;
  myProfile;
  year: number;
  socialLinks: typeof SocialMedia = SocialMedia;
  menuItems: MenuItem[];
  constructor(
    private http: HttpClientService,
    private gc: GlobalConstantsService,
    private subMenus: SubMeunus
  ) {}

  ngOnInit(): void {
    this.http.http.get('/assets/data/footer-links.json').subscribe((data) => {
      this.data = data;
      this.links = this.data.links;
      this.myProfile = this.data.myProfile;
    });
    this.year = this.gc.year;

    /**
     * getting menu items
     */
    this.subMenus.getLinks().subscribe((data: MenuItem[]) => {
      this.menuItems = data;
    });
  }
}
