import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export abstract class SubMeunus {
  constructor(private http: HttpClient) {}
  getLinks() {
    return this.http.get('/assets/data/menu.json');
  }
  getSingleLinks() {
    return this.http.get('/assets/data/single-links-menu.json');
  }
}
