<div class="container pt-3">
  <div class="establishment-statistics-container">
    <h3 class="c-second">Statistiques</h3>
    <div class="row">
      <div class="col-md-6 col-lg-4 py-3">
        <div id="chartdiv" style="width: 100%; height: 250px"></div>
      </div>
      <div class="col-md-6 col-lg-4 py-3">
        <div id="chartdivColumns" style="width: 100%; height: 300px"></div>
      </div>
      <div class="col-md-6 col-lg-4 py-3">
        <div id="chartdiv2" style="width: 100%; height: 250px"></div>
      </div>
    </div>
  </div>
</div>
