<div
  class="container-fluid pb-5 pt-0 page-not-found costum-height bg-cover bg-14"
>
  <div class="container">
    <div class="">
      <div class="col-md-4 mx-auto">
        <img
          src="/assets/images/404-image.svg"
          alt=""
          class="w-100 image-404"
        />
      </div>
      <div class="text-center col-md-8 mx-auto c-second py-5">
        <h2 class="mb-3">
          {{ "page404.t1" | translate }}
        </h2>
        <p class="col-md-10 mx-auto mb-3">
          {{ "page404.t2" | translate }}
        </p>
        <a [routerLink]="['/']" class="rounded-btn-second">{{
          "shared.titles.home" | translate
        }}</a>
      </div>
    </div>
  </div>
</div>
