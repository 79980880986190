import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-space',
  template: `
    <div class="padding-for-menu">
      <router-outlet></router-outlet>
    </div>
  `,
})
export class MySpaceComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
