import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { LocalStorageService } from '../../helpers/local-storage.service';
import { Redirection } from '../../helpers/redirection';
import { Response, SignIn, User } from '../../models';
import { Credentials } from '../../models/interfaces/credentials';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$ = new BehaviorSubject<User>(null);
  constructor(
    private apiService: ApiService,
    private localStorageService: LocalStorageService
  ) {}

  /**
   * login
   * @param email email
   * @param password
   */
  login(credentials: Credentials): Observable<SignIn> {
    return this.apiService.post('/auth/sign-in', credentials).pipe(
      tap((response: SignIn) => {
        this.user$.next(response.user);
        this.localStorageService.set('token', response.token);
        this.localStorageService.set('refreshToken', response.refreshToken);
      })
    );
  }

  /**
   * logout function
   */
  logout() {
    // remove user from local storage to log user out
    this.localStorageService.remove('token');
    this.localStorageService.remove('refreshToken');
    this.user$.next(null);
    Redirection.login();
  }

  /**
   * forgot password
   * @param email
   */
  forgotPassword(email: string): Observable<Response> {
    return this.apiService.post('/auth/forgot-password', { email: email });
  }

  /**
   * reset password
   * @param password
   * @param confirmPassword
   */
  resetPassword(data): Observable<Response> {
    return this.apiService.post('/auth/reset-password', data);
  }

  /**
   * refresh token
   */
  refreshToken(): Observable<{ token: string; refreshToken: string }> {
    const refreshToken = this.localStorageService.get('refreshToken');
    return this.apiService.post('/auth/refresh', { refreshToken }).pipe(
      tap((response) => {
        this.localStorageService.set('token', response.accessToken);
        this.localStorageService.set('refreshToken', response.refreshToken);
      })
    );
  }

  getCurrentUser(): Observable<User> {
    return this.user$.pipe(
      switchMap((user) => {
        // check if we already have user data
        if (user) {
          return of(user);
        }

        const token = this.localStorageService.get('token');
        // if there is token then fetch the current user
        if (token) {
          return this.fetchCurrentUser();
        }

        return of(null);
      })
    );
  }

  fetchCurrentUser(): Observable<User> {
    return this.apiService.post('/auth/is-authorized').pipe(
      tap((user) => {
        this.user$.next(user);
      })
    );
  }
}
