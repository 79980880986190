import { Component, OnInit } from '@angular/core';
import { Redirection } from 'src/app/core/helpers/redirection';
import { RoutesEnum } from 'src/app/core/models';
import { EstablishmentService } from 'src/app/core/services/crud/establishment.service';

@Component({
  selector: 'app-establishment-overview',
  templateUrl: './establishment-overview.component.html',
  styleUrls: ['./establishment-overview.component.scss'],
})
export class EstablishmentOverviewComponent implements OnInit {
  public routes: typeof RoutesEnum = RoutesEnum;
  establishment: any;
  isReady: boolean = false;
  constructor(private establishmentService: EstablishmentService) {}

  ngOnInit(): void {
    this.establishmentService.get().subscribe((data) => {
      this.establishment = data;
      this.isReady = true;
    });
  }
  onUpdate() {
    Redirection.updateEstablishment;
  }
}
