<input
  type="file"
  (change)="onFileChange($event)"
  accept="image/x-png,image/jpeg"
  [hidden]="image"
  #file
/>
<div *ngIf="image">
  <img
    [src]="
      image.type === 'local'
        ? (image.file | toBase64 | async)
        : (image.file | baseUrl: 'apiUrl')
    "
    alt="image"
    (click)="file.click()"
  />
</div>
