import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RoutesEnum } from '../models';

@Pipe({
  name: 'baseUrl',
})
export class BaseUrlPipe implements PipeTransform {
  baseUerl = environment.api.baseUrl + '/';
  localImagesUrl = '/assets/images/';
  localIconsUrl = '/assets/icons/';
  /**
   * api getter
   * @param value
   * @param type "'apiUrl'  or 'localImage' or 'localIcon'"
   */
  transform(value: string, type: string): string {
    switch (type) {
      case 'apiUrl':
        return this.baseUerl + value;
        break;
      case 'news':
        return RoutesEnum.NEWSBASE + value;
        break;
      case 'localImage':
        return this.localImagesUrl + value;
        break;
      case 'localIcon':
        return this.localIconsUrl + value;
        break;

      default:
        return this.baseUerl + value;
        break;
    }
  }
}
