<div id="menu-nav" class="container-fluid menu-nav">
  <div class="container px-0">
    <nav class="navbar navbar-expand-lg p-0">
      <a class="navbar-brand d-lg-none" [routerLink]="'home' | navigate">
        <div class="p-2">
          <img
            [src]="'Flag.svg' | baseUrl: 'localImage'"
            alt=""
            class="nav-logo"
          />
        </div>
      </a>
      <!-- Step 3: Toggle the value of the property when the toggler button is clicked. -->
      <button
        class="navbar-toggler my-2"
        type="button"
        (click)="showMobileNav = true"
      >
        &#9776;
      </button>
      <!-- Step 2: Add the ngbCollapse directive to the element below. -->
      <div
        [ngbCollapse]="isMenuCollapsed"
        class="navbar-collapse d-none d-lg-block"
      >
        <ul class="navbar-nav">
          <li class="nav-item">
            <a [routerLink]="'home' | navigate" class="nav-link">Home</a>
          </li>
          <li
            *ngFor="let item of menuItems; let i = index"
            ngbDropdown
            class="nav-item active"
            (click)="isMenuCollapsed = false"
          >
            <!-- Step 4: Close the menu when a link is clicked. -->
            <a
              class="nav-link"
              [id]="item.id"
              ngbDropdownToggle
              (click)="isMenuCollapsed = false"
              [ngClass]="{ 'no-dropdown': !item.children.length }"
              [routerLink]="constructRouterLink(item)"
              >{{ item.label | translate }}</a
            >
            <div
              *ngIf="item.children.length"
              ngbDropdownMenu
              class="dropDown-menu"
            >
              <ng-container *ngFor="let item2 of item.children">
                <a
                  *ngIf="isExternal(item2)"
                  class="c-second"
                  [href]="item2.url"
                  (click)="navigate(item2.url)"
                  target="_blank"
                  ngbDropdownItem
                  >{{ item2.label }}</a
                >
                <a
                  *ngIf="!isExternal(item2)"
                  class="c-second"
                  (click)="isMenuCollapsed = true"
                  [routerLink]="
                    item2.isSystem
                      ? '/' + item2.url
                      : '/' + routesEnum.PAGE + '/' + item2.url
                  "
                  ngbDropdownItem
                  >{{ item2.label }}</a
                >
              </ng-container>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>

<!-- nav menu -->
<div *ngIf="showMobileNav" class="mobile-menu d-block d-lg-none">
  <div class="mobile-nav-container">
    <div class="text-right">
      <button (click)="showMobileNav = false">
        <img [src]="'close-line.svg' | baseUrl: 'localIcon'" alt="" />
      </button>
    </div>
    <ul class="" (click)="isMenuCollapsed = true">
      <li class="nav-item" (click)="showMobileNav = false">
        <a [routerLink]="'home' | navigate" class="nav-link">Home</a>
      </li>
      <li
        *ngFor="let item of menuItems; let i = index"
        ngbDropdown
        class="nav-item active"
      >
        <!-- Step 4: Close the menu when a link is clicked. -->
        <a
          class="nav-link"
          [id]="'nav-mobile-' + item.id"
          ngbDropdownToggle
          [ngClass]="{ 'no-dropdown': !item.children.length }"
          [routerLink]="constructRouterLink(item)"
          (click)="showMobileNav = item.children.length"
          >{{ item.label }}</a
        >
        <div *ngIf="item.children.length" ngbDropdownMenu class="dropDown-menu">
          <!-- <a
            *ngFor="let item2 of item.children"
            class=""
            [routerLink]="[item2.url]"
            ngbDropdownItem
            (click)="showMobileNav = false"
            >{{ item2.label | translate }}</a
          > -->
          <ng-container *ngFor="let item2 of item.children">
            <a
              *ngIf="isExternal(item2)"
              class="c-second"
              [href]="item2.url"
              (click)="navigate(item2.url)"
              target="_blank"
              ngbDropdownItem
              >{{ item2.label }}</a
            >
            <a
              *ngIf="!isExternal(item2)"
              class="c-second"
              (click)="showMobileNav = false"
              [routerLink]="
                item2.isSystem
                  ? '/' + item2.url
                  : '/' + routesEnum.PAGE + '/' + item2.url
              "
              ngbDropdownItem
              >{{ item2.label }}</a
            >
          </ng-container>
        </div>
      </li>

      <li
        (click)="showMobileNav = false"
        class="nav-item"
        style="font-size: 13px"
      >
        <div *ngIf="!user">
          <img
            src="/assets/icons/sign-in.svg"
            class="mr-2"
            height="30px"
            alt=""
          />
          <span class="t-primary text-uppercase"
            ><a [routerLink]="[routes.AUTH]">{{
              "home.titles.signIn" | translate
            }}</a></span
          >
        </div>
        <div *ngIf="user" class="text-center">
          <span class="t-primary text-uppercase"
            ><a class="hover-pointer" [routerLink]="'DASHBOARD' | navigate"
              >Dashboard</a
            ></span
          >
          <br />
          <br />
          <span class="t-primary text-uppercase"
            ><a class="hover-pointer" (click)="logout()"
              >Se déconnecter</a
            ></span
          >
        </div>
      </li>
    </ul>
  </div>
</div>
