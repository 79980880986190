<!-- app top nav -->
<div class="container-fluid top-nav d-none d-lg-block">
  <div class="container">
    <div class="row py-3">
      <div
        class="
          col-12
          py-3 py-lg-0
          text-center
          col-md-6 col-lg-2
          my-auto
          d-none d-md-block
        "
      >
        <img
          [routerLink]="['/']"
          src="/assets/images/Flag.svg"
          alt=""
          class="clean-a"
          height="100px"
        />
      </div>
      <div
        class="
          col-12
          py-3 py-lg-0
          text-center
          col-md-6 col-lg-2
          my-auto
          d-none d-lg-block
        "
      >
        <!-- <input type="text" class="input-1" /> -->
      </div>
      <div
        class="
          col-12
          py-3 py-lg-0
          text-center
          col-md-6 col-lg-2
          my-auto
          d-none d-lg-block
          text-center
        "
      >
        <div>
          <a target="_blank" [href]="socialLinks.FB">
            <img class="mx-1" src="/assets/icons/facebook-1.svg" alt="" />
          </a>
          <a target="_blank" [href]="socialLinks.INSTA">
            <img class="mx-1" src="/assets/icons/instagram.svg" alt="" />
          </a>
          <a target="_blank" [href]="socialLinks.TWITTER">
            <img class="mx-1" src="/assets/icons/twitter.svg" alt="" />
          </a>
          <a target="_blank" [href]="socialLinks.YOUTUBE">
            <img class="mx-1" src="/assets/icons/youtube-play.svg" alt="" />
          </a>
        </div>
      </div>
      <div class="col-12 py-0 py-lg-0 text-center col-md-6 col-lg-4 my-auto">
        <div *ngIf="!user">
          <img
            src="/assets/icons/sign-in.svg"
            class="mr-2"
            height="40px"
            alt=""
          />
          <span class="t-primary text-uppercase"
            ><a [routerLink]="[routes.AUTH]">{{
              "home.titles.signIn" | translate
            }}</a></span
          >
        </div>
        <div *ngIf="user">
          <div ngbDropdown class="profile-dropdown">
            <button
              class="btn btn-outline-primary"
              id="dropdownBasic1"
              ngbDropdownToggle
            >
              <img
                src="/assets/icons/dashboard.png"
                class="mr-2"
                height="40px"
                alt=""
              />
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button ngbDropdownItem [routerLink]="'DASHBOARD' | navigate">
                Dashboard
              </button>
              <button ngbDropdownItem (click)="logout()">Se déconnecter</button>
            </div>
          </div>

          <!-- <div>
            <a [routerLink]="'DASHBOARD' | navigate">
              <img
                src="/assets/icons/dashboard.png"
                class="mr-2"
                height="40px"
                alt=""
              />
            </a>
            <span class="t-primary text-uppercase small-text ml-3"
              ><a class="hover-pointer" (click)="logout()"
                >Se déconnecter</a
              ></span
            >
          </div> -->
        </div>
      </div>
      <div
        class="
          col-12
          py-3 py-lg-0
          text-center
          col-md-6 col-lg-2
          my-auto
          d-none d-lg-block
        "
      >
        <a
          href="https://ec.europa.eu/programmes/erasmus-plus/about_fr"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div class="row">
            <div class="p-0 m-auto">
              <img
                src="/assets/images/flag-2.svg"
                height="48px"
                class="mr-1"
                alt=""
              />
            </div>
            <div class="col p-0 m-auto">
              <p class="image-side-text m-0 text-left">
                Funded by the European Union
              </p>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
<!-- end of app top nav -->
<!-- app main nav -->
<!-- <div id="menu-nav" class="container-fluid menu-nav">
  <div class="container px-0">
    <nav class="navbar navbar-expand-lg p-0">
      <a class="navbar-brand d-lg-none" [routerLink]="'home' | navigate">
        <div class="p-2">
          <img
            [src]="'Flag.svg' | baseUrl: 'localImage'"
            alt=""
            class="nav-logo"
          />
        </div>
      </a> -->
<!-- Step 3: Toggle the value of the property when the toggler button is clicked. -->
<!-- <button
        class="navbar-toggler my-2"
        type="button"
        (click)="showMobileNav = true"
      >
        &#9776;
      </button> -->
<!-- Step 2: Add the ngbCollapse directive to the element below. -->
<!-- <div
        [ngbCollapse]="isMenuCollapsed"
        class="navbar-collapse d-none d-lg-block"
      >
        <ul class="navbar-nav">
          <li class="nav-item">
            <a [routerLink]="'home' | navigate" class="nav-link">Home</a>
          </li>
          <li
            *ngFor="let item of menuItems; let i = index"
            ngbDropdown
            class="nav-item active"
            (click)="isMenuCollapsed = false"
          > -->
<!-- Step 4: Close the menu when a link is clicked. -->
<!-- <a
              class="nav-link"
              [id]="item.id"
              ngbDropdownToggle
              (click)="isMenuCollapsed = false"
              >{{ item.text | translate }}</a
            >
            <div ngbDropdownMenu class="dropDown-menu">
              <a
                *ngFor="let item2 of item.subMenu"
                class="c-second"
                (click)="isMenuCollapsed = true"
                [routerLink]="[item2.url]"
                ngbDropdownItem
                href=""
                >{{ item2.text | translate }}</a
              >
            </div>
          </li> -->
<!-- no dropdown links-->
<!-- <li class="nav-item" *ngFor="let singleLink of singleLinksMenuItems">
            <a
              class="nav-link"
              [routerLink]="[singleLink.url]"
              (click)="isMenuCollapsed = true"
              >{{ singleLink.text | translate }}</a
            >
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div> -->
<app-main-menu></app-main-menu>
<!-- end of app main nav -->

<div class="spinner-holder" *ngIf="httpLoading">
  <div class="spinner-grow" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<div class="spinner-holder" *ngIf="routeLoading">
  <div class="spinner-grow" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
<router-outlet></router-outlet>
<!--  mobile menu -->
<div *ngIf="showMobileNav" class="mobile-menu d-block d-lg-none">
  <div class="mobile-nav-container">
    <div class="text-right">
      <button (click)="showMobileNav = false">
        <img [src]="'close-line.svg' | baseUrl: 'localIcon'" alt="" />
      </button>
    </div>
    <ul class="" (click)="isMenuCollapsed = true">
      <li class="nav-item" (click)="showMobileNav = false">
        <a [routerLink]="'home' | navigate" class="nav-link">Home</a>
      </li>
      <li
        *ngFor="let item of menuItems; let i = index"
        ngbDropdown
        class="nav-item active"
      >
        <!-- Step 4: Close the menu when a link is clicked. -->
        <a class="nav-link" [id]="'nav-mobile-' + item.id" ngbDropdownToggle>{{
          item.text | translate
        }}</a>
        <div ngbDropdownMenu class="dropDown-menu">
          <a
            *ngFor="let item2 of item.subMenu"
            class=""
            [routerLink]="[item2.url]"
            ngbDropdownItem
            (click)="showMobileNav = false"
            >{{ item2.text | translate }}</a
          >
        </div>
      </li>
      <!-- no dropdown links-->
      <li
        (click)="showMobileNav = false"
        class="nav-item"
        *ngFor="let singleLink of singleLinksMenuItems"
      >
        <a class="nav-link" [routerLink]="[singleLink.url]">{{
          singleLink.text | translate
        }}</a>
      </li>
      <li
        (click)="showMobileNav = false"
        class="nav-item"
        *ngFor="let singleLink of singleLinksMenuItems"
      >
        <a class="nav-link" [routerLink]="[singleLink.url]">{{
          singleLink.text | translate
        }}</a>
      </li>
      <li
        (click)="showMobileNav = false"
        class="nav-item"
        style="font-size: 13px"
      >
        <div *ngIf="!user">
          <img
            src="/assets/icons/sign-in.svg"
            class="mr-2"
            height="30px"
            alt=""
          />
          <span class="t-primary text-uppercase"
            ><a [routerLink]="[routes.AUTH]">{{
              "home.titles.signIn" | translate
            }}</a></span
          >
        </div>
        <div *ngIf="user" class="text-center">
          <span class="t-primary text-uppercase"
            ><a class="hover-pointer" [routerLink]="'DASHBOARD' | navigate"
              >Dashboard</a
            ></span
          >
          <br />
          <br />
          <span class="t-primary text-uppercase"
            ><a class="hover-pointer" (click)="logout()"
              >Se déconnecter</a
            ></span
          >
        </div>
      </li>
    </ul>
  </div>
</div>
