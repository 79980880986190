import { Component, OnInit } from '@angular/core';
import { ToastrNotificationsService } from 'src/app/core/services/adaptors/toastr-notifications.service';
import * as Handsontable from 'handsontable';
import * as XLSX from 'xlsx';
import { Establishment } from 'src/app/core/models/classes/establishment';
import { HotTableRegisterer } from '@handsontable/angular';
import { HotTableService } from 'src/app/core/services/adaptors/hot-table.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Example } from 'src/app/core/models/interfaces/example';

@Component({
  selector: 'app-establishment',
  templateUrl: './establishment.component.html',
  styleUrls: ['./establishment.component.scss'],
})
export class EstablishmentComponent implements OnInit {
  isloaded: boolean = false;
  establishments$: Observable<Example[]>;
  constructor(
    private notify: ToastrNotificationsService,
    private hotTable: HotTableService,
    private http: HttpClient
  ) {}
  dataishere: boolean = false;
  ngOnInit(): void {}
  /* handling excel files */

  onFileChange(ev) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      /* const dataString = JSON.stringify(jsonData); */
      // console.log(jsonData);
      this.setExcelData(jsonData.Sheet1);
      /*  document.getElementById('output').innerHTML = dataString.slice(0, 300).concat("...");
      this.setDownload(dataString); */
    };
    reader.readAsBinaryString(file);
  }

  /* end of handling excel files */

  /* handsontable */

  initExcelTable: boolean = false;
  tableSettings: any;
  dataset: any[];

  setExcelData(data) {
    this.hotTable.setExcelData(data);
    this.tableSettings = this.hotTable.tableSettings;
    this.dataset = this.hotTable.dataset;
    this.initExcelTable = true;
  }
  resetExcelTable() {}
  showData() {
    console.log(this.hotTable.dataset);
  }

  /* end of handsontable */

  showSuccess() {
    this.notify.success({ message: 'succes message here', ToastConfig: {} });
  }
  showError() {
    this.notify.error({ message: 'error message here', ToastConfig: {} });
  }
  showInfo() {
    this.notify.info({ message: 'info', ToastConfig: {} });
  }
}
