import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GlobalConstantsService {
  constructor() {}
  get year() {
    return new Date().getFullYear();
  }
}
