import { path } from '@amcharts/amcharts4/core';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, ChildrenOutletContexts } from '@angular/router';
import { RoutesEnum } from './core/models';
import { AuthGuard } from './core/services/guards/auth.guard';
import { MainComponent, MySpaceComponent } from './layout';
import { PageComponent } from './shared/component/page/page.component';
import { DownloadNewFileComponent } from './shared/components';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: RoutesEnum.PAGESLUG,
        component: PageComponent,
      },
      {
        path: '',
        loadChildren: () =>
          import('./pages/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'national-office',
        loadChildren: () =>
          import('./pages/national-office/national-office.module').then(
            (m) => m.NationalOfficeModule
          ),
      },
      {
        path: 'how-to-benefit',
        loadChildren: () =>
          import('./pages/how-to-benefit/how-to-benefit.module').then(
            (m) => m.HowToBenefitModule
          ),
      },
      {
        path: 'espace-alumni',
        loadChildren: () =>
          import('./pages/espace-alumni/espace-alumni.module').then(
            (m) => m.EspaceAlumniModule
          ),
      },
      {
        path: 'partner-search',
        loadChildren: () =>
          import('./pages/partner-search/partner-search.module').then(
            (m) => m.PartnerSearchModule
          ),
      },
      {
        path: 'reform-experts',
        loadChildren: () =>
          import('./pages/reform-experts/reform-experts.module').then(
            (m) => m.ReformExpertsModule
          ),
      },
      {
        path: 'erasmus-in-morocco',
        loadChildren: () =>
          import('./pages/erasmus-in-morocco/erasmus-in-morocco.module').then(
            (m) => m.ErasmusInMoroccoModule
          ),
      },
      {
        path: RoutesEnum.SINGLENEWS,
        loadChildren: () =>
          import('./pages/single-article/single-article.module').then(
            (m) => m.SingleArticleModule
          ),
      },
      {
        path: RoutesEnum.NEWS,
        loadChildren: () =>
          import('./pages/news/news.module').then((m) => m.NewsModule),
      },
    ],
  },
  {
    path: 'my-space',
    component: MySpaceComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'statistics',
        loadChildren: () =>
          import('./pages/statistics/statistics.module').then(
            (m) => m.StatisticsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'establishment',
        loadChildren: () =>
          import('./pages/establishment/establishment.module').then(
            (m) => m.EstablishmentModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'icm',
        loadChildren: () =>
          import('./pages/icm/icm.module').then((m) => m.IcmModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'c-j-e',
        loadChildren: () =>
          import('./pages/c-j-e/c-j-e.module').then((m) => m.CJEModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'erasmus-numbers',
        loadChildren: () =>
          import('./pages/analytic/analytic.module').then(
            (m) => m.AnalyticModule
          ),
        // import('./pages/erasmus-numbers/erasmus-numbers.module').then(
        //   (m) => m.ErasmusNumbersModule
        // ),
        canActivate: [AuthGuard],
      },
      {
        path: 'resourses',
        loadChildren: () =>
          import('./pages/resourses/resourses.module').then(
            (m) => m.ResoursesModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'forum',
        loadChildren: () =>
          import('./pages/forum/forum.module').then((m) => m.ForumModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./pages/profile/profile.module').then((m) => m.ProfileModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'c-b-h-e',
        loadChildren: () =>
          import('./pages/c-b-h-e/c-b-h-e.module').then((m) => m.CBHEModule),
        // canActivate: [AuthGuard],
      },
      {
        path: 'jean-monnet',
        loadChildren: () =>
          import('./pages/jean-monnet/jean-monnet.module').then(
            (m) => m.JeanMonnetModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'e-m-j-m-d',
        loadChildren: () =>
          import('./pages/e-m-j-m-d/e-m-j-m-d.module').then(
            (m) => m.EMJMDModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: RoutesEnum.UPLOADFILE,
        component: DownloadNewFileComponent,
      },
    ],
  },
  {
    path: ':slug',
    component: MainComponent,
    children: [
      {
        path: '',
        component: PageComponent,
      },
    ],
  },
  { path: RoutesEnum.NOTFOUND, component: PageNotFoundComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
