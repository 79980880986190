import { BrowserModule } from '@angular/platform-browser';
import { Injector, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';

import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslationService } from './core/services/global/translation.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderInterceptor } from './core/services/interceptors/loader.interceptor';

import { ToastrModule } from 'ngx-toastr';
import { MainComponent, MySpaceComponent } from './layout';
import { Router } from '@angular/router';
import { Redirection } from './core/helpers/redirection';
import { TokenInterceptor } from './core/services/interceptors/token.interceptor';
import { PageComponent } from './shared/component/page/page.component';

export function translationInitializer(translationService: TranslationService) {
  return function () {
    return translationService.init('en');
  };
}
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
export let appInjector: Injector;
@NgModule({
  declarations: [AppComponent, MySpaceComponent, MainComponent, PageComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 1000,
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injectorService: Injector, private router: Router) {
    appInjector = this.injectorService;
    Redirection.router = router;
  }
}
