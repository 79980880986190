import { Component, OnInit } from '@angular/core';
import { RoutingStateService } from 'src/app/core/services/global/routing-state.service';

@Component({
  selector: 'app-previous-page',
  template: `
    <div class="container pt-4 pb-4">
      <a [routerLink]="['/my-space/dashboard']" class="clean-a goback-btn">
        <img src="/assets/icons/btn-precedent.svg" alt="" />

        {{ 'shared.titles.home' | translate }}
      </a>
    </div>
  `,
})
export class PreviousPageComponent implements OnInit {
  previousRoute: string;
  constructor(private routingState: RoutingStateService) {}

  ngOnInit(): void {
    this.previousRoute = this.routingState.getPreviousUrl();
  }
}
