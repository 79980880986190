<div class="simple-card">
  <img [src]="item.image | baseUrl: 'apiUrl'" alt="" class="w-100 image" />
  <h2 class="title">{{ item.title }}</h2>
  <p
    class="description pb-2"
    [innerHTML]="item.description | truncate: 300"
  ></p>
  <!-- <div
    class="description card-html pb-2"
    [innerHTML]="innerHtml | safeHtml"
  ></div> -->

  <div class="mt-4">
    <!-- [routerLink]="item.url" -->
    <app-p-button [routerLink]="'/actualities/' + item.slug">{{
      "shared.actions.knowMore" | translate
    }}</app-p-button>
  </div>
</div>
