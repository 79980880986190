<div class="p-2 home-news-card">
  <!-- <p class="light-para">{{ singleNews. }}</p> -->
  <h3 [innerHTML]="item.title | truncate: 80"></h3>
  <img
    [src]="item.image | baseUrl: 'apiUrl'"
    class="mb-1"
    alt=""
    class="singleNews-image"
  />
  <!-- <p [innerHTML]="singleNews.content | truncate: 130"></p> -->
  <div class="description pb-2" [innerHTML]="innerHtml | safeHtml"></div>
  <a [routerLink]="'/actualities/' + item.title" class="link-arrow"
    >learn more
    <img src="/assets/icons/arrow-long-right.svg" alt="" />
  </a>
</div>
