<div class="p-2 container-fluid new-icm">
  <div class="row w-100 m-0 pb-4">
    <h3 *ngIf="activePages[0]" class="col my-auto">
      télécharger un fichier pour {{ title }}
    </h3>
    <h3 *ngIf="activePages[1]" class="col my-auto">mapping</h3>
    <h3 *ngIf="activePages[2]" class="col my-auto">téléchargement</h3>
    <span class="float-right hover-pointer" (click)="close()">
      <a class="close-btn">
        <img src="/assets/icons/close-line.svg" alt="" />
      </a>
    </span>
  </div>
  <div
    [ngClass]="{ 'd-none': !activePages[0] }"
    class="page-1 mt-5 single-page"
  >
    <div class="mx-auto max-width-500 grow-1">
      <div class="p-3">
        <form [formGroup]="uploadForm" (ngSubmit)="onSubmit()">
          <input
            type="file"
            (change)="onFileChange($event)"
            class="custom-file-input"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        </form>
      </div>
      <div *ngIf="fileIsReady" class="p-3 mt-4">
        <div class="file-container row w-100 m-0">
          <div class="csv-container">
            <img src="/assets/icons/csv.svg" alt="" class="" />
          </div>
          <div class="col">{{ fileName }}</div>
        </div>
      </div>
    </div>
    <div class="text-right mt-3">
      <button
        (click)="upload()"
        [disabled]="!fileIsReady"
        class="rounded-btn-primary"
      >
        télécharger ce fichier
      </button>
    </div>
  </div>
  <div [ngClass]="{ 'd-none': !activePages[1] }" class="page-2 single-page">
    <div class="p-3 grow-1">
      <div class="row-container">
        <div class="row w-100 m-0">
          <div class="col-md-4">text one here</div>
          <div class="col-md-4">
            <ul>
              <li *ngFor="let data of previwData[0]">{{ data }}</li>
            </ul>
          </div>
          <div class="col-md-4">
            <select number="0" (change)="onChange($event)">
              <option *ngFor="let field of fields">
                {{ field }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row-container">
        <div class="row w-100 m-0">
          <div class="col-md-4">text one here</div>
          <div class="col-md-4">
            <ul>
              <li *ngFor="let data of previwData[1]">{{ data }}</li>
            </ul>
          </div>
          <div class="col-md-4">
            <select number="1" (change)="onChange($event)">
              <option *ngFor="let field of fields">
                {{ field }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row-container">
        <div class="row w-100 m-0">
          <div class="col-md-4">text one here</div>
          <div class="col-md-4">
            <ul>
              <li *ngFor="let data of previwData[2]">{{ data }}</li>
            </ul>
          </div>
          <div class="col-md-4">
            <select number="2" (change)="onChange($event)">
              <option *ngFor="let field of fields">
                {{ field }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3 pb-1">
      <a
        (click)="activatePage(0)"
        class="rounded-btn-second icon-left hover-pointer float-left"
      >
        {{ "shared.actions.previous" | translate }}
      </a>
      <button
        (click)="activatePage(2)"
        class="rounded-btn-primary hover-pointer float-right"
      >
        {{ "shared.actions.download" | translate }}
      </button>
    </div>
  </div>
  <div [ngClass]="{ 'd-none': !activePages[2] }" class="single-page">
    <div *ngIf="loading" class="p-3 h-100">
      <div class="loading-container h-100">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <div *ngIf="!loading" class="text-center">
      <p>{{ statusMessage }}</p>
    </div>
    <div class="mt-3 pb-1">
      <button
        (click)="goBack()"
        class="rounded-btn-primary hover-pointer float-right"
      >
        Terminer
      </button>
    </div>
  </div>
</div>
